/* import __COLOCATED_TEMPLATE__ from './attribute-tooltip.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: {
    text: string;
  };
}

const AttributeTooltip = templateOnlyComponent<Signature>();
export default AttributeTooltip;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::Attribute::AttributeTooltip': typeof AttributeTooltip;
    'inbox2/conversation-details-sidebar/attribute/attribute-tooltip': typeof AttributeTooltip;
  }
}
