/* import __COLOCATED_TEMPLATE__ from './table.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import {
  type NavigationMode,
  type SortState,
} from 'embercom/components/inbox2/conversations-table';
import { type ConversationsTableResource } from './conversations-table-resource';
import type ConversationTableEntry from 'embercom/objects/inbox/conversation-table-entry';
import { modifier } from 'ember-modifier';
import type ConversationsTableActions from 'embercom/objects/inbox/conversations-table-actions';
import { isEmpty } from 'underscore';
import { action } from '@ember/object';
import {
  type ColumnDefinition,
  type ColumnGroup,
} from 'embercom/objects/inbox/conversations-table-column';
import type ConversationsTableColumn from 'embercom/objects/inbox/conversations-table-column';
import { isSameColumn } from 'embercom/objects/inbox/conversations-table-column';
import type CompanySummary from 'embercom/objects/inbox/company-summary';
import { type SortDirection } from 'embercom/services/inbox-api';

interface Args {
  columns: ConversationsTableColumn[];
  conversationEntries: ConversationTableEntry[];
  sortColumn: (column: ConversationsTableColumn, direction: SortDirection) => void;
  sortState: SortState;
  onConversationClicked: (conversation: ConversationTableEntry) => void;
  loadMore: () => void;
  navigationMode: NavigationMode;
  conversationsTableActions: ConversationsTableActions;
  onColumnsChanged: (columns: ColumnDefinition[]) => void;
  estimateHeight?: number;
  staticHeight?: boolean;
  padLastRow?: boolean;
  hiddenColumns: ColumnDefinition[] | ColumnGroup[];
  shouldScrollToActiveConversation: boolean;
  onPreviewGithubIssues: (conversation: ConversationTableEntry) => void;
  isTableInFocus: boolean;
  closePreviewPanel: () => void;
  onConversationHovered?: (id: number) => void;
  onConversationSelected: (
    options: { toggleAll?: boolean; conversation?: ConversationTableEntry },
    event?: PointerEvent,
  ) => void;
  reloadConversations: () => void;
  canLoadMore: boolean;
  hasError: boolean;
  activeConversation?: ConversationTableEntry;
  isLoadingInForeground: boolean;
  isInitialLoad: boolean;
  companyFilter?: CompanySummary;
}

interface Signature {
  Args: Args;
  Element: HTMLElement;
}

export default class Table extends Component<Signature> {
  setContainerWidth = modifier((element: any) => {
    let width = this.args.columns.reduce((sumWidth, column) => sumWidth + column.width, 0) + 128; // + 128px for the + column selector
    element.style.width = `${width}px`;
  });

  resetScrollPosition = modifier((element, [conversations]: [ConversationsTableResource]) => {
    if (conversations.conversations.length === 0) {
      element.scrollTop = 0;
    }
  });

  get conversations(): (
    | (ConversationTableEntry & { isSkeleton?: undefined })
    | { isSkeleton: true; id: string }
  )[] {
    let realConversations = this.args.conversationEntries;

    let skeletons = [1, 2, 3].map((id) => ({ isSkeleton: true as const, id: `s${id}` }));
    return [...realConversations, ...(this.args.canLoadMore ? skeletons : [])];
  }

  get columns() {
    return this.args.columns;
  }

  get isEmpty() {
    return isEmpty(this.args.conversationEntries);
  }

  get shouldDisplayErrorState() {
    return this.isEmpty && this.args.hasError;
  }

  get isReordering() {
    return this.columns.any((column) => column.isReordering);
  }

  get isResizing() {
    return this.columns.any((column) => column.isResizing);
  }

  @action onColumnsChanged(columns: ConversationsTableColumn[]) {
    this.args.onColumnsChanged([
      ...columns.filter((column) => column.isFixed),
      ...columns.filter((column) => !column.isFixed),
    ]);
  }

  @action hideColumn(columnToHide: ConversationsTableColumn) {
    this.args.onColumnsChanged(
      this.columns.filter((column) => !isSameColumn(columnToHide, column)),
    );
  }

  @action showColumn(columnToShow: ConversationsTableColumn) {
    this.args.onColumnsChanged([...this.columns, columnToShow]);
  }

  @action showColumns(columnsToShow: ConversationsTableColumn[]) {
    this.args.onColumnsChanged([...this.columns, ...columnsToShow]);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationsTable::Table': typeof Table;
    'inbox2/conversations-table/table': typeof Table;
  }
}
