/* import __COLOCATED_TEMPLATE__ from './conversation-reply-composer.hbs'; */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { BlocksDocument, type ComposerPublicAPI } from '@intercom/embercom-prosemirror-composer';
import { type EditorState } from 'prosemirror-state';
import Conversation, {
  type MessageSender,
  TicketSystemState,
  NewConversation,
} from 'embercom/objects/inbox/conversation';
import {
  type CustomInputRuleConfig,
  SkinToneModifiers,
} from '@intercom/embercom-prosemirror-composer/lib/config/composer-config';
import { action } from '@ember/object';
import type InboxState from 'embercom/services/inbox-state';
import { ComposerLocation, InboxEvents } from 'embercom/services/inbox-state';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
// @ts-ignore
import { trackedReset } from 'tracked-toolbox';
import type IntlService from 'embercom/services/intl';
import type CommandKService from 'embercom/services/command-k';
import { DisplayContext } from 'embercom/services/command-k';
import type SavedReplyInsertionsService from 'embercom/services/saved-reply-insertions-service';
import { type ReplyChannel } from 'embercom/objects/inbox/composer-pane';
import type ComposerPane from 'embercom/objects/inbox/composer-pane';
import {
  ComposerPaneType,
  EmailChannel,
  replyChannelIsEmail,
  replyChannelIsMultiParticipant,
  ReplyChannelType,
} from 'embercom/objects/inbox/composer-pane';
import { type MacroAction } from 'embercom/objects/inbox/macro';
import type Macro from 'embercom/objects/inbox/macro';
import type Session from 'embercom/services/session';
import {
  type Block,
  type BlockList,
  type Html,
  type List,
  type MessengerCardBlock,
  type Paragraph,
} from '@intercom/interblocks.ts';
import EmbercomFileUploader from 'embercom/lib/articles/embercom-file-uploader';
import { useResource } from 'ember-resources';
import { trackedFunction } from 'ember-resources/util/function';
import ComposerPaneResource from './composer/composer-pane-resource';
import { type DurationObject, DurationType } from 'embercom/objects/inbox/duration';
import trimWhitespaces from 'embercom/lib/inbox/composer-helpers/trim-whitespaces';
import { Config } from 'embercom/objects/inbox/composer-config';
import { type Hotkey, type HotkeysMap } from 'embercom/services/inbox-hotkeys';
import type InboxHotkeys from 'embercom/services/inbox-hotkeys';
import { HotkeyID } from 'embercom/services/inbox-hotkeys/HotkeyID';
import platform from 'embercom/lib/browser-platform';
import type SmartReply from 'embercom/objects/inbox/smart-reply';
import { type ParticipantWireFormat, type ReplyDataType } from 'embercom/services/inbox-api';
import type InboxApi from 'embercom/services/inbox-api';
import type AttributesApi from 'embercom/services/attributes-api';
import { Channel, SOCIAL_CHANNELS } from 'embercom/models/data/inbox/channels';
import {
  AllowedImageFileTypesWhatsapp,
  lookupChannelSupportedType,
} from 'embercom/helpers/lookup-channel-supported-type';
import { TicketCategory, type TicketType } from 'embercom/objects/inbox/ticket';
import type WhatsappTemplate from 'embercom/objects/inbox/whatsapp-template';
import { TimedRestrictedRepliesResource } from 'embercom/components/inbox2/timed-restricted-replies-resource';
import type RouterService from '@ember/routing/router-service';
import type MessengerApps from 'embercom/services/messenger-apps';
import type MessengerApp from 'embercom/objects/inbox/messenger-app';
import AttributesResolver from 'embercom/objects/inbox/attributes-resolver';
import { getOwner, setOwner } from '@ember/application';
import UserSummary, {
  getLatestUpdatedParticipants,
  latestUpdatedParticipantsStorageKey,
  getParticipantChanges,
  latestUpdatedRecipientsListStorageKey,
} from 'embercom/objects/inbox/user-summary';
import { isEmpty, isPresent } from '@ember/utils';
import storage from 'embercom/vendor/intercom/storage';
import { throttleTask } from 'ember-lifeline';
import ENV from 'embercom/config/environment';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import { request } from 'embercom/lib/inbox/requests';
import { PhoneNumber, type PhoneNumberWireFormat } from 'embercom/objects/inbox/phone-number';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import type UserEmailComment from 'embercom/objects/inbox/renderable/user-email-comment';
import {
  type SendAndCloseFn,
  type SendAndSetStateFn,
  type SendAndSnoozeFn,
  type SendFn,
  type InserterDetail,
  type InserterId,
  type SendResult,
} from 'embercom/objects/inbox/types/composer';
import type Snackbar from 'embercom/services/snackbar';
import type ArticlesApi from 'embercom/services/articles-api';
import type TracingService from 'embercom/services/tracing';
import {
  type DefaultYellowSkinTone,
  type NoSkinToneModifier,
} from 'embercom/services/emoji-service';
// @ts-ignore
import { trackedRef } from 'ember-ref-bucket';
import moment from 'moment-timezone';
import { last } from 'underscore';
import { captureException } from 'embercom/lib/sentry';
import AIAssist from 'embercom/objects/inbox/command-k/ai-assist';
import { type TaskGenerator } from 'ember-concurrency';
import { htmlToTextContent } from 'embercom/lib/html-unescape';
import { InboxCategory } from 'embercom/models/data/inbox/inbox-categories';
import { InboxType } from 'embercom/models/data/inbox/inbox-types';
import { USAGE_LIMIT_STATUS } from 'embercom/models/data/sms/constants';
import AiAssistApi, { type AiAssistPromptKey } from 'embercom/resources/inbox2/composer/ai-assist';
import EmailHistoryApi from 'embercom/resources/inbox2/composer/email-history';
import type AiAssistSettings from 'embercom/services/ai-assist-settings';
import type TicketCustomState from 'embercom/objects/inbox/ticket-custom-state';
import type InboxSidebarService from 'embercom/services/inbox-sidebar-service';
import type CopilotApi from 'embercom/services/copilot-api';
import type TicketStateService from 'embercom/services/ticket-state-service';
import type IntercomConfirmService from 'embercom/services/intercom-confirm-service';
import type RenderablePart from 'embercom/objects/inbox/renderable-part';
import type LogService from 'embercom/services/log-service';
import { type CopilotSuggestion } from 'embercom/resources/inbox2/copilot/copilot-question-suggestions';
import { CopilotSuggestionLocation } from 'embercom/lib/inbox2/copilot/types';
import { ConversationState } from 'embercom/objects/inbox/conversation';
import CopilotQuestionSuggestions from 'embercom/resources/inbox2/copilot/copilot-question-suggestions';
import { next } from '@ember/runloop';
import {
  createRecipients,
  createRecipientsWithoutDraft,
  getRecipientsForPart,
  getRecipientsListFromMetadata,
} from 'embercom/lib/composer/recipients-factory';
import { type Recipients } from 'embercom/lib/composer/recipients';
import type ConversationUpdates from 'embercom/services/conversation-updates';
import { type OutboundFieldVisibilitySettings } from 'embercom/components/inbox2/composer/outbound-fields';

export type SetPaneSource = 'command_k' | 'pane_picker' | 'pane_picker_dbl_click';

export const SEND_AND_CLOSE_HINT_TIMESTAMPS = 'send_and_close_hint_timestamps';
// Keep this in sync with Emails::Constants::Attachments::MAX_INLINE_ATTACHMENT_SIZE on back-end
export const MAX_INLINE_ATTACHMENT_SIZE = 18000000;
export const COMPOSER_INSERTERS: readonly InserterDetail[] = [
  {
    type: 'macro',
    id: 'use-macro',
    icon: 'saved-reply',
    iconSet: 'standard',
    textKey: 'inbox.composer.inserters.macros',
    hotkeyId: HotkeyID.UseMacro,
  },
  {
    type: 'emoji',
    id: 'emoji',
    icon: 'lwr-happy',
    iconSet: 'standard',
    textKey: 'inbox.composer.inserters.emojis',
    hotkeyId: HotkeyID.Emoji,
  },
  {
    type: 'gif',
    id: 'gifs',
    icon: 'gif',
    iconSet: 'standard',
    textKey: 'inbox.composer.inserters.gifs',
    hotkeyId: HotkeyID.InsertGif,
  },
  {
    type: 'article',
    id: 'insert-article',
    icon: 'article',
    iconSet: 'standard',
    textKey: 'inbox.composer.inserters.articles',
    hotkeyId: HotkeyID.InsertArticle,
  },
  {
    type: 'attachment',
    id: 'upload-attachment',
    icon: 'attachment',
    iconSet: 'standard',
    textKey: 'inbox.composer.inserters.attachments',
    hotkeyId: HotkeyID.AddAttachment,
  },
  {
    type: 'image',
    id: 'upload-image',
    icon: 'picture',
    iconSet: 'standard',
    textKey: 'inbox.composer.inserters.images',
    hotkeyId: HotkeyID.InsertImage,
  },
] as const;

const COLLABORATOR_SEAT_INSERTER_ID_ALLOW_LIST = new Set<string>([
  'emoji',
  'gifs',
  'upload-attachment',
  'upload-image',
]);

const MINIMUM_SEND_AND_CLOSE_BUTTON_WIDTH = 190;
const INSERTERS_BUTTON_WIDTH = 32;

function makeTableInserter(): InserterDetail {
  return {
    type: 'table',
    id: 'table',
    icon: 'table',
    textKey: 'inbox.composer.inserters.table',
  } as const;
}

function makeProductToursInserter(): InserterDetail {
  return {
    type: 'app',
    id: 'app-intercom-tours',
    icon: 'product-tours-filled',
    textKey: 'inbox.composer.inserters.tours',
  } as const;
}

function makeAiAssistInserter(): InserterDetail {
  return {
    type: 'aiAssist',
    id: AIAssist.id as InserterId,
    icon: 'ai',
    textKey: 'inbox.composer.inserters.ai-assist',
    hotkeyId: HotkeyID.AiAssist,
  } as const;
}

function makeCopilotSuggestionsInserter(): InserterDetail {
  return {
    type: 'copilotSuggestions',
    id: 'copilot-suggestions',
    icon: 'fin',
    textKey: 'inbox.composer.inserters.copilot-suggestions',
  } as const;
}

export const makeKnowledgeHubInserter = (): InserterDetail => {
  return {
    type: 'knowledgeBase',
    id: 'open-knowledge-base',
    icon: 'insights',
    iconSet: 'standard',
    textKey: 'inbox.composer.inserters.knowledge-base',
    hotkeyId: HotkeyID.OpenKnowledgeBasePanel,
  } as const;
};

export const makeTriggerWorkflowInserter = (): InserterDetail => {
  return {
    type: 'image',
    id: 'trigger-workflow',
    icon: 'workflows',
    iconSet: 'standard',
    textKey: 'inbox.composer.inserters.trigger-reusable-workflow',
  } as const;
};

function getTrimmedHtmlContentLength(element: HTMLElement) {
  return element.textContent?.replace(/\s+/g, ' ').trim().length || 0;
}

export interface PublicAPI {
  focus: () => void;
  setPane: (pane: ComposerPaneType) => void;
  setActiveReplyPane: () => BlockList;
  insertBlocks: (blocks: BlockList, conversationId?: number) => void;
  replaceBlocks: (blocks: BlockList, conversationId?: number) => void;
  setActiveNotePane: () => BlockList;
  insertMacroActions: (actions: MacroAction[]) => void;
  api: ComposerPublicAPI;
  aiAssistCompletion: (promptKey: AiAssistPromptKey, blocks: BlockList) => Promise<BlockList>;
}

// isSideConversationComposer should be set to true when the composer is used to create/reply to a side conversation.
//
// When set to true the composer will behave differently:
// - The pane picker is not visible
// - The sender field is not visible
// - The send button does not have the option to snooze/close
type Args = {
  conversation: Conversation | NewConversation;
  replyChannel: Channel;
  onTyping?: (type: ComposerPaneType) => void;
  onChange?: () => void;
  keyboardPriority?: number;
  onReady?: (api: PublicAPI) => void;
  onBlur?: () => unknown;
  activePane?: ComposerPaneType;
  onChannelChange?: (channel: ReplyChannel, fromStorage: boolean) => unknown;
  canSend?: boolean;
  isDisabled?: boolean;
  location?: ComposerLocation;
  defaultToNotePane: boolean;
  smartReply: SmartReply | null;
  onSend: SendFn;
  onSendAndClose?: SendAndCloseFn;
  onSendAndSnooze?: SendAndSnoozeFn;
  onSendAndSetState?: SendAndSetStateFn;
  onSendComplete?: () => unknown;
  sender?: MessageSender;
  onSenderChange?: (sender: any) => void;
  recipients?: UserSummary[];
  onRecipientChange?: (recipients?: UserSummary[]) => void;
  onRecipientManagerChange?: (recipient?: Recipients) => void;
  title?: string;
  setTitle?: Function;
  isCreatingConversation?: boolean;
  canBeLarge?: boolean;
  canShowSendAndCloseHint?: boolean;
  onDismissSendAndCloseHint?: () => void;
  skinToneModifier: SkinToneModifiers | DefaultYellowSkinTone | NoSkinToneModifier;
  switchToNotePane?: boolean;
  hideNotePane?: boolean;
  forwardedPartId?: string;
  sendSeparately?: boolean;
  toggleSendSeparately?: () => void;
  setRecipientErrors?: (
    recipientsWithErrors: { recipient: UserSummary; recipientError: string | void }[],
  ) => void;
  outboundFieldVisibilitySettings?: OutboundFieldVisibilitySettings;
  isTicketNotesComposer?: boolean;
  isSideConversationComposer?: boolean;
  supportedReplyChannels?: ReplyChannel[];
  pessimisticClearOnSend?: boolean;
  canSendMessenger?: boolean;
  canSendEmail?: boolean;
  canSendWhatsapp?: boolean;
  canSendSMS?: boolean;
};

interface Signature {
  Args: Args;
  Element: HTMLDivElement;
}

export default class ReplyComposer extends Component<Signature> {
  @service declare commandK: CommandKService;
  @service declare inboxApi: InboxApi;
  @service declare articlesApi: ArticlesApi;
  @service declare inboxState: InboxState;
  @service declare intercomEventService: any;
  @service declare intercomConfirmService: IntercomConfirmService;
  @service declare intl: IntlService;
  @service declare session: Session;
  @service declare attributesApi: AttributesApi;
  @service declare savedReplyInsertionsService: SavedReplyInsertionsService;
  @service declare router: RouterService;
  @service declare messengerApps: MessengerApps;
  @service declare inboxHotkeys: InboxHotkeys;
  @service declare snackbar: Snackbar;
  @service declare tracing: TracingService;
  @service declare notificationsService: any;
  @service declare aiAssistSettings: AiAssistSettings;
  @service declare inboxSidebarService: InboxSidebarService;
  @service declare copilotApi: CopilotApi;
  @service declare ticketStateService: TicketStateService;
  @service declare logService: LogService;
  @service declare conversationUpdates: ConversationUpdates;

  @tracked insertedWhatsappTemplate: WhatsappTemplate | undefined;
  @tracked api?: ComposerPublicAPI;
  @tracked config: Config;
  @tracked allowedSmsCountries?: Array<string>;
  @tracked showTicketVisibilityModal = false;
  @tracked showSmartReplyToolbar = false;

  @tracked activeInserter?: { id: InserterId; source: string };

  _previousReplyChannel: Channel = this.args.replyChannel;
  @tracked selectedApp?: MessengerApp;
  _previousConversationId?: number = this.args.conversation.id;
  _previousDefaultToNotePane: boolean = this.args.defaultToNotePane;
  _previousSwitchToNotePane?: boolean = this.args.switchToNotePane;
  panes = useResource(this, ComposerPaneResource, () => ({
    conversationId: this.args.conversation.id,
    replyChannel: this.args.replyChannel,
    defaultToNotePane: this.args.defaultToNotePane || !this.canReply || !this.canReplyToInbound,
    isConversationLoading: this.args.conversation.isLoading,
    switchToNotePane: this.args.switchToNotePane,
    isTicketNotesComposer: this.args.isTicketNotesComposer,
    hideNotePane: this.args.hideNotePane,
  }));
  timedRestrictedReplies = useResource(this, TimedRestrictedRepliesResource, () => ({
    conversation: this.args.conversation,
    lastUpdatedAt: this.args.conversation.socialPreventRepliesLastUpdatedAt,
  }));
  readonly hotkeys: HotkeysMap;
  readonly handleEditorHotkey;
  readonly sendHotkey: Hotkey;
  readonly sendAndCrossPostHotkey: Hotkey;

  allowedAttachmentFileTypes = ['*'];
  autofocus = false;
  @tracked recipientsWithErrors: { recipient: UserSummary; recipientError: string | void }[] = [];
  @trackedReset('conversationParticipantsState') updatedParticipants: UserSummary[] =
    this.getUpdatedParticipants();
  @trackedReset('recipientsState') recipientsList: Recipients = this.buildRecipientsList();
  @trackedReset('recipientsState') defaultRecipientsList: Recipients =
    this.buildDefaultRecipientsList();

  readonly latestUpdatedParticipantsStorageKey = latestUpdatedParticipantsStorageKey;
  readonly latestUpdatedRecipientsListStorageKey = latestUpdatedRecipientsListStorageKey;
  readonly replyChannelIsMultiParticipant = replyChannelIsMultiParticipant;
  readonly getLatestUpdatedParticipants = getLatestUpdatedParticipants;
  readonly getParticipantChanges = getParticipantChanges;
  @trackedReset('args.conversation.id') hasOpenedRecipientSelector = false;
  @tracked isComposerFocused = false;
  @tracked canCreateNewSmsConversation = true;
  @tracked nrHiddenRecipients = 10;
  @tracked insertersPopoverOpen = false;
  @trackedReset('args.conversation.id') hasDraftParticipantChanges =
    this.checkDraftParticipantChanges();
  @trackedReset('args.conversation.id') recipientSelectorAutofocus = false;
  @trackedReset('args.conversation.id') didStartTyping = false;
  @tracked loadingForwardingContext = !!this.args.forwardedPartId;

  @trackedReset('args.conversation.id') historyExpanded = this.historyExpandedInitialValue;
  @trackedReset('args.conversation.id') repliedToPart: RenderablePart | undefined = undefined;
  @trackedRef('shown-suggested-content') declare shownSuggestedContent?: HTMLElement;
  @trackedRef('inserted-suggested-content') declare insertedSuggestedContent?: HTMLElement;
  @trackedRef('actions-container') declare actionsContainer?: HTMLElement;
  @trackedRef('shortcut-and-inserters-container')
  declare shortcutAndInsertersContainer?: HTMLElement;

  get canUseTicketsConditionalAttributes() {
    return this.session.workspace.canUseTicketsConditionalAttributes;
  }

  get canUseMessengerConditionalAttributes() {
    return this.session.workspace.canUseMessengerConditionalAttributes;
  }

  copilotQuestionSuggestions = CopilotQuestionSuggestions.from(this, () => {
    return {
      conversationId: this.args.conversation.id,
      userCommentId: (this.args.conversation as Conversation).lastUserComment?.id,
      hasSuggestionsPinned: this.hasCopilotSuggestionsPinned,
      isConversationLoading: this.args.conversation.isLoading,
      hasIngestedContent: this.copilotApi.hasIngestedContent,
    };
  });

  private aiAssistApi = AiAssistApi.from(this, () => {
    return {
      composerApi: this.api,
      currentBlocks: this.panes.activePane?.blocksDoc.blocks,
      conversationId: this.args.conversation.id,
    };
  });

  private emailHistoryApi = EmailHistoryApi.from(this, () => {
    return {
      conversationId: this.args.conversation.id,
    };
  });

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.config = this.buildConfig(this.panes.activePane);

    if (!this.session.showLightInbox) {
      taskFor(this.loadAllowedPhoneCountries).perform();
    }

    if (args.forwardedPartId) {
      taskFor(this.loadForwardingContext).perform(args.forwardedPartId);
    }

    if (this.args.activePane) {
      this.setPaneType(this.args.activePane);
    }

    if (this.args.forwardedPartId) {
      this.setChannel(EmailChannel);
    } else if (this.latestReplyChannel) {
      this.setChannel(this.latestReplyChannel, { fromStorage: true });
    }

    if (this.args.isSideConversationComposer) {
      this.setupSideConversationComposer();
    }

    this.hotkeys = this.inboxHotkeys.hotkeysMap;
    this.handleEditorHotkey = this.inboxHotkeys.handleEditorHotkey;
    this.sendHotkey = this.hotkeys[HotkeyID.Send];
    this.sendAndCrossPostHotkey = this.hotkeys[HotkeyID.SendAndCrossPost];

    this.inboxState.on(InboxEvents.ReplyToEmailPart, this, this.fillEmailPartData);
  }

  @action openKnowledgeBase() {
    this.inboxSidebarService.openKnowledgeBasePanel(
      this.args.conversation.id,
      this.args.conversation.firstParticipant?.id,
    );

    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'inbox_knowledge_base',
      section: 'composer',
      conversation_id: this.conversationId,
    });
  }

  willDestroy() {
    super.willDestroy();
    this.inboxState.off(InboxEvents.ReplyToEmailPart, this, this.fillEmailPartData);
  }

  fillEmailPartData(conversationPart: any, partConversationId?: number) {
    if (partConversationId !== this.conversationId) {
      return;
    }
    this.clearEmailHistoryParams();
    this.panes.replyPane.clear();
    this.repliedToPart = conversationPart;
    taskFor(this.loadEmailPartParticipants).perform(this.conversationId, conversationPart.entityId);
    this.loadAndExpandEmailHistory(conversationPart.entityId);
  }

  get activatingComponent(): DisplayContext {
    return this.args.isSideConversationComposer
      ? DisplayContext.SideConversation
      : DisplayContext.Global;
  }

  get isAppSupportedChannel(): boolean {
    return this.replyPaneIsActive && lookupChannelSupportedType(this.args.replyChannel, 'app');
  }

  get taskOrTracker(): boolean {
    return (
      this.args.conversation.ticketCategory === TicketCategory.Task ||
      this.args.conversation.ticketCategory === TicketCategory.Tracker
    );
  }

  get displayAppInserters(): boolean {
    return this.isAppSupportedChannel;
  }

  get displayTourInserter(): boolean {
    return this.displayAppInserters;
  }

  get hasAIAssistEnabled(): boolean {
    return this.aiAssistSettings.hasAnyAiAssistInInbox;
  }

  get isknowledgeHubEnabled() {
    return this.session.workspace.isFeatureEnabled('psg-inbox-knowledge-base');
  }

  get lastRecipientIndex(): number {
    return this.ccEnabled
      ? this.recipientsList.all.length - 1
      : this.updatedParticipants.length - 1;
  }

  get isDisabled(): boolean | undefined {
    return this.args.isDisabled || (!!this.args.forwardedPartId && this.loadingForwardingContext);
  }

  get insertedSuggestedContentLength(): number {
    return this.insertedSuggestedContent
      ? getTrimmedHtmlContentLength(this.insertedSuggestedContent)
      : 0;
  }

  get shownSuggestedContentLength(): number {
    return this.shownSuggestedContent ? getTrimmedHtmlContentLength(this.shownSuggestedContent) : 0;
  }

  get conversationParticipantsState(): string {
    return `${this.args.conversation.id}${this.args.conversation.participantSummaries
      .map((p) => p.id)
      .join('')}`;
  }

  get recipientLoadedFromQueryParam(): boolean {
    return (
      this.isNewConversation &&
      (this.args.conversation as NewConversation).isRecipientLoadedFromQueryParams
    );
  }

  get recipientsState(): string {
    return `${this.args.conversation.id}${this.args.conversation.lastPart?.entityId}${this.recipientLoadedFromQueryParam}`;
  }

  get shouldShowSendAndCrossPostButton() {
    return (
      this.args.isTicketNotesComposer && this.args.conversation.linkedCustomerReportIds.length > 0
    );
  }

  get crossPostNoteTooltipText() {
    let ticketCategoryLabel = this.args.conversation.isTrackerTicket
      ? TicketCategory.Tracker
      : TicketCategory.Task;
    return this.intl.t(
      `inbox.composer.ticket-add-note-and-cross-post-tooltip-for-${ticketCategoryLabel}`,
    );
  }

  get addComposeNoteCmdKAction() {
    return !this.isNewConversation && !this.args.isSideConversationComposer;
  }

  get hasCopilotSuggestionsPinned() {
    return (
      this.canDisplayCopilotSuggestionsInserter &&
      this.pinnedInserterIds.includes('copilot-suggestions')
    );
  }

  @action openCopilot() {
    this.inboxSidebarService.openCopilot();
  }

  @action handleCopilotSuggestionClick(suggestion: CopilotSuggestion, suggestionIndex = 0) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'question_hint',
      section: 'composer',
      context: CopilotSuggestionLocation.ExternalCarousel,
      question: suggestion.type === 'question' ? suggestion.question : null,
      macro_id: suggestion.type === 'macro' ? suggestion.macro.id : null,
      question_index: suggestionIndex,
      answer_bot_transaction_id: suggestion.answerBotTransactionId,
      last_part_entity_type: this.conversationInstance?.lastRenderablePart?.entityType,
      last_part_entity_id: this.conversationInstance?.lastRenderablePart?.entityId,
      conversation_id: this.conversationInstance?.id,
    });

    this.copilotQuestionSuggestions.handleSuggestionClick(suggestion, {
      insertBlocks: this.insertBlocks,
    });

    next(() => this.focus());
  }

  @action handleCopilotSuggestionRejected(suggestion: CopilotSuggestion) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'rejected',
      object: 'question_hint',
      section: 'composer',
      context: CopilotSuggestionLocation.ExternalCarousel,
      question: suggestion.type === 'question' ? suggestion.question : null,
      macro_id: suggestion.type === 'macro' ? suggestion.macro.id : null,
      answer_bot_transaction_id: suggestion.answerBotTransactionId,
      last_part_entity_type: this.conversationInstance?.lastRenderablePart?.entityType,
      last_part_entity_id: this.conversationInstance?.lastRenderablePart?.entityId,
      conversation_id: this.conversationInstance?.id,
    });

    this.copilotQuestionSuggestions.handleSuggestionRejected(suggestion);
  }

  @action instrumentCopilotSuggestionShow(suggestion: CopilotSuggestion, suggestionIndex = 0) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'shown',
      object: 'question_hint',
      section: 'composer',
      context: CopilotSuggestionLocation.ExternalCarousel,
      question: suggestion.type === 'question' ? suggestion.question : null,
      macro: suggestion.type === 'macro' ? suggestion.macro.name : null,
      question_index: suggestionIndex,
      answer_bot_transaction_id: suggestion.answerBotTransactionId,
      last_part_entity_type: this.conversationInstance?.lastRenderablePart?.entityType,
      last_part_entity_id: this.conversationInstance?.lastRenderablePart?.entityId,
      conversation_id: this.conversationInstance?.id,
    });
  }

  // This a quick fix to update the composer with latest config (adding mentions to the config if the chnaged convo's last part is a note)
  // When the conversation is changed, we don't want to focus the  composer as the focus is handled by pressing enter key in conversation list
  @action handleConversationChanged(_: any, id: number[]) {
    let currentConversationId = id?.length && id.pop();
    if (
      currentConversationId &&
      this._previousConversationId &&
      currentConversationId !== this._previousConversationId
    ) {
      this._previousConversationId = currentConversationId;
      this.autofocus = false;
      this.config = this.buildConfig(this.panes.activePane);
    }
  }

  // hack to update the replyChannel appropriately. The conversation summary seems to set the reply channel as unknown, which is why it does not get updated to the correct value.
  @action handleReplyChannelChanged(_: any, replyChannels: Channel[]) {
    let currentReplyChannel = replyChannels?.length && replyChannels.pop();
    if (
      currentReplyChannel &&
      this._previousReplyChannel &&
      currentReplyChannel !== this._previousReplyChannel
    ) {
      this._previousReplyChannel = currentReplyChannel;
      this.config = this.buildConfig(this.panes.activePane);
    }
  }

  @action handleDefaultToNotePaneChanged(_: any, values: boolean[]) {
    let currentDefaultToNotePane = values?.length && values.pop();

    if (
      typeof currentDefaultToNotePane === 'boolean' &&
      currentDefaultToNotePane !== this._previousDefaultToNotePane
    ) {
      this._previousDefaultToNotePane = currentDefaultToNotePane;
      this.config = this.buildConfig(this.panes.activePane);
    }
  }

  @action handleSwitchToNotePaneChanged(_: any, values: boolean[]) {
    let currentSwitchToNotePane = values?.length && values.pop();

    if (
      typeof currentSwitchToNotePane === 'boolean' &&
      currentSwitchToNotePane !== this._previousSwitchToNotePane
    ) {
      this._previousSwitchToNotePane = currentSwitchToNotePane;
      this.config = this.buildConfig(this.panes.activePane);
    }
  }

  setupSideConversationComposer() {
    this.panes.replyPane.channel = EmailChannel;
    this.panes.setActivePane(this.panes.activePane, new BlocksDocument([]));
  }

  @action handleRecipientsChanged() {
    this.updatedParticipants = this.args.recipients || [];
  }

  @action handleSkinToneModifierChanged() {
    this.config = this.buildConfig(this.panes.activePane);
  }

  @action handleAiAssistSettingsChanged() {
    this.config = this.buildConfig(this.panes.activePane);
  }

  @task *loadAllowedPhoneCountries() {
    let phoneNumbers: Array<PhoneNumber> = yield this.fetchSmsPhoneNumbers();
    this.allowedSmsCountries = phoneNumbers.map((number) => number.countryCode);
  }

  async fetchSmsPhoneNumbers() {
    let response = await request(`/ember/sms/phone_number?app_id=${this.session.workspace.id}`);
    let data = (await response.json()) as Array<PhoneNumberWireFormat>;
    return data.map((json) => PhoneNumber.deserialize(json));
  }

  private buildConfig(pane: ComposerPane): Config {
    let allowMentions = true;
    if (pane === this.panes.replyPane) {
      allowMentions = false;
    }

    let resolver = new AttributesResolver();
    setOwner(resolver, getOwner(this));

    let config = new Config(
      getOwner(this),
      this.session.workspace.id,
      {
        allowedImageFileTypes: this.allowedImageFileTypes,
        allowedAttachmentTypes: this.allowedAttachmentFileTypes,
        uploader: EmbercomFileUploader,
        attrs: { policyUrl: `/apps/${this.session.workspace.id}/uploads` },
        onUploadStart: () => {
          this.api?.composer.commands.focus();
          this.tracing.startRootSpan({ name: 'image_upload', resource: 'image_upload:inbox2' });
        },
      },
      this.createMacroInputRule(),
      this.isNewConversation ? resolver : undefined,
      allowMentions,
      undefined,
      { useMacro: this.useMacro, conversationId: this.conversationId },
    );
    config.placeholder = '';
    config.disableModKAnchor = true;
    config.enableAutoLink = true;

    config.enableHighlighting = this.session.workspace.isFeatureEnabled(
      'inbox2-smart-reply-toolbar',
    );

    if (this.isReplyChannelSms && this.replyPaneIsActive) {
      config.allowedBlocks = config.allowedBlocks.filter(
        (allowedBlock) => !['heading', 'subheading', 'codeBlock'].includes(allowedBlock),
      );
      config.allowedInline = [];
      config.inputRules = [];
    }

    if (this.isReplyChannelEmail || this.notePaneIsActive) {
      config.enableTables();
    }

    let skinToneModifier = this.args.skinToneModifier;
    config.skinToneModifier =
      skinToneModifier === ('default-yellow-skin-tone' as DefaultYellowSkinTone) ||
      skinToneModifier === null
        ? SkinToneModifiers.NoSkinToneModifier
        : skinToneModifier;

    if (this.aiAssistSettings.textTransformations) {
      config.formatters = {
        placement: 'left' as const,
        config: [
          {
            componentName: 'inbox2/composer/toolbar-ai-assist',
            componentActions: {
              aiAssistCompletion: this.aiAssistCompletion.bind(this),
            },
          },
        ],
      };
    }

    return config;
  }

  get conversationId() {
    return this.args.conversation.id;
  }

  get allowedImageFileTypes() {
    if (this.args.replyChannel === Channel.Whatsapp) {
      return AllowedImageFileTypesWhatsapp;
    }
    return ['image/png', 'image/jpeg', 'image/jpg', 'image/gif'];
  }

  get isNewConversation(): boolean {
    return this.args.conversation instanceof NewConversation;
  }

  get participantData() {
    let existingParticipants = this.args.conversation.participantSummaries;
    let newParticipants = this.getUpdatedParticipants() || this.recipientsList?.all;
    let { addedParticipants, removedParticipants } = this.getParticipantChanges(
      existingParticipants,
      newParticipants,
    );

    let removedParticipantIds = removedParticipants.map((participant) => participant.id);

    let newParticipantEmails: string[] = [];
    let newParticipantIds: string[] = [];
    addedParticipants?.forEach((participant) =>
      participant.isNewUser
        ? newParticipantEmails.push(participant.email!)
        : newParticipantIds.push(participant.id),
    );

    let data: ParticipantWireFormat = {
      removedParticipantIds,
      newParticipantEmails,
      newParticipantIds,
    };

    if (this.ccEnabled && this.recipientsList) {
      data.recipients = this.recipientsList.serialize();
    }

    return data;
  }

  resetRecipientsListToDefault() {
    this.recipientsList = this.defaultRecipientsList;
    this.clearRecipientsStorage();
  }

  @action onRecipientManagerChange(recipients: Recipients) {
    this.updatedParticipants = recipients.all;
    this.recipientsList = recipients;
    this.updateRecipientsLocalStorage(recipients);

    this.onRecipientChange(recipients.all);

    if (this.args.onRecipientManagerChange) {
      this.args.onRecipientManagerChange(recipients);
    }

    // Disable send separately if there are CC recipients.
    if (
      this.disableSendSeparatelyToggle &&
      this.args.sendSeparately &&
      this.args.toggleSendSeparately
    ) {
      this.args.toggleSendSeparately();
    }
  }

  @action onRecipientChange(recipients: UserSummary[]) {
    if (
      this.updatedParticipants.length < 2 &&
      recipients.length === 2 &&
      this.showSendSeparatelyToggle
    ) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'shown',
        object: 'send_separately_toggle',
        section: 'new_conversation',
      });
    }

    this.updatedParticipants = recipients;
    this.updateParticipantsLocalStorage(recipients);

    if (this.args.onRecipientChange) {
      this.args.onRecipientChange(recipients);
    }
  }

  updateRecipientsLocalStorage(recipients: Recipients) {
    if (this.isSideConversationDraft) {
      return;
    }
    storage.set(
      this.latestUpdatedRecipientsListStorageKey(this.session.workspace.id, this.conversationId),
      recipients,
    );
  }

  updateParticipantsLocalStorage(recipients: UserSummary[]) {
    if (this.isSideConversationDraft) {
      return;
    }

    if (recipients.length < 1) {
      storage.remove(
        this.latestUpdatedParticipantsStorageKey(this.session.workspace.id, this.conversationId),
      );
    } else {
      storage.set(
        this.latestUpdatedParticipantsStorageKey(this.session.workspace.id, this.conversationId),
        recipients,
      );
    }
  }

  get isSideConversationDraft() {
    return this.args.isSideConversationComposer && this.args.conversation.id === undefined;
  }

  getUpdatedParticipants(): UserSummary[] {
    if (this.isSideConversationDraft) {
      return this.args.recipients || this.args.conversation.participantSummaries || [];
    }

    return (
      this.latestUpdatedParticipants ||
      this.args.recipients ||
      this.args.conversation.participantSummaries ||
      []
    );
  }

  buildRecipientsList() {
    return createRecipients(this.args.conversation, this.session.workspace.id);
  }

  buildDefaultRecipientsList() {
    return createRecipientsWithoutDraft(this.args.conversation);
  }

  checkDraftParticipantChanges() {
    let existingParticipants = this.args.conversation.participantSummaries;
    let newParticipants = this.getUpdatedParticipants();
    let { addedParticipants, removedParticipants } = this.getParticipantChanges(
      existingParticipants,
      newParticipants,
    );

    return addedParticipants.length > 0 || removedParticipants.length > 0;
  }

  get hasRecipientError() {
    return (
      this.isRecipientRequired &&
      (this.updatedParticipants.length < 1 ||
        this.recipientsWithErrors.any(({ recipientError }) => recipientError !== undefined))
    );
  }

  get conversationsList() {
    return [this.args.conversation] as Conversation[] | [NewConversation];
  }

  get isEmailCurrentChannel() {
    let currentChannel = this.conversationInstance?.channel.current;
    return currentChannel
      ? currentChannel === Channel.Email
      : this.panes.activePane.channel?.type === ReplyChannelType.Email;
  }

  @action setRecipientErrors(
    recipientsWithErrors: { recipient: UserSummary; recipientError: string | void }[],
  ) {
    this.recipientsWithErrors = recipientsWithErrors;
  }

  get isRecipientRequired() {
    return this.args.conversation.ticketCategory !== TicketCategory.Tracker;
  }

  get fetchTotalAttachmentSize() {
    let attachmentList = this.attachmentBlocks;
    if (!attachmentList) {
      return 0;
    }
    let totalSize = attachmentList.attachments.reduce(
      (total, attachment) => total + attachment.size,
      0,
    );
    return totalSize;
  }

  get hasExceededInlineAttachmentsSizeLimit() {
    if (!this.inlineAttachmentSettingEnabled) {
      return false;
    }
    if (!replyChannelIsEmail(this.panes.replyPane.channel) || this.notePaneIsActive) {
      return false;
    }
    return this.fetchTotalAttachmentSize > MAX_INLINE_ATTACHMENT_SIZE;
  }

  get canSend(): boolean {
    if (this.isUploading) {
      return false;
    }

    if (!this.isSendChannelValid) {
      return false;
    }

    if (this.isWhatsappCurrentChannel && this.replyPaneIsActive) {
      return this.canSendToWhatsapp;
    }

    if (this.hasExceededInlineAttachmentsSizeLimit) {
      return false;
    }

    return (
      !this.composerIsEmpty &&
      (this.args.canSend ?? true) &&
      !this.hasRecipientError &&
      (this.notePaneIsActive || this.hasToRecipient)
    );
  }

  get hasToRecipient() {
    if (this.ccEnabled) {
      return this.recipientsList.to.length > 0;
    } else {
      return true;
    }
  }

  get canSendToWhatsapp(): boolean {
    if (!this.isWhatsappCurrentChannel) {
      return false;
    }

    let canSendArg = this.args.canSend ?? true;
    if (this.timedRestrictedReplies.preventWhatsappReplies) {
      return canSendArg && Boolean(this.insertedWhatsappTemplate);
    } else if (this.outboundWhatsappConversation) {
      return canSendArg && Boolean(this.insertedWhatsappTemplate);
    } else {
      return canSendArg && (!this.composerIsEmpty || Boolean(this.insertedWhatsappTemplate));
    }
  }

  get isWhatsappCurrentChannel(): boolean {
    return this.panes.activePane.channel?.type === ReplyChannelType.Whatsapp;
  }

  get isChatCurrentChannel(): boolean {
    return this.panes.activePane.channel?.type === ReplyChannelType.Chat;
  }

  get isSMSCurrentChannel(): boolean {
    return this.panes.activePane.channel?.type === ReplyChannelType.SMS;
  }

  get replyData(): ReplyDataType | undefined {
    if (this.insertedWhatsappTemplate) {
      return {
        whatsapp_template_name: this.insertedWhatsappTemplate.name,
        whatsapp_template_language: this.insertedWhatsappTemplate.language,
        whatsapp_template_components: this.insertedWhatsappTemplate.components,
      };
    }
    return;
  }

  get socialPreventRepliesLastUpdatedAt(): number | undefined {
    return this.args.conversation.socialPreventRepliesLastUpdatedAt;
  }

  get currentComposerBlocks() {
    return this.panes.activePane.blocksDoc.blocks;
  }

  get attachmentBlocks() {
    return this.panes.activePane.blocksDoc.onlyAttachmentListBlock;
  }

  get isActiveComposer(): boolean {
    return this.args.location === this.inboxState.activeComposerLocation;
  }

  createMacroInputRule() {
    let handleMacroInput = (_state: EditorState, match: string[], _start: number, _end: number) => {
      this.commandK.registerAndShow(
        {
          actionID: 'use-macro',
          onSelect: (macro) => {
            this.useMacro(macro);
          },
          onCancel: () => {
            this.reinsertMacroInputMatcher(match[0]);
          },
          trackingData: {
            action: 'added',
            object: 'macro',
            source: 'composer',
          },
          context: {
            pane: this.panes.activePane.type,
            isStartingConversation: this.isNewConversation,
          },
        },
        {
          shortcutUsed: match[0],
          source: 'composer',
        },
      );
    };
    return {
      type: 'custom',
      matcher: this.inboxHotkeys.macroMatcher,
      handler(state: EditorState, match: string[], start: number, end: number) {
        handleMacroInput(state, match, start, end);
        return state.tr;
      },
    } as CustomInputRuleConfig;
  }

  @action reinsertMacroInputMatcher(match: string) {
    this.api?.composer.commands.insertText(match);
    this.api?.composer.commands.focus();
  }

  @action focus() {
    this.api?.composer.commands.focus();
  }

  private assertConversationGuardrails(conversationId?: number) {
    if (this.conversationId !== conversationId) {
      let error = new Error('Attempted to insert blocks into the wrong conversation composer');
      let stack = error.stack?.split('\n').slice(1, 15).join('\n');
      this.logService.log({
        log_type: 'composerInsertBlocks',
        expected_conversation_id: this.conversationId,
        actual_conversation_id: conversationId,
        stack,
      });
      if (
        this.session.workspace.isFeatureEnabled('composer-insertion-mismatch-killswitch') &&
        ENV.environment !== 'test'
      ) {
        captureException(error);
      } else {
        throw error;
      }
    }
  }

  @action insertBlocks(blocks: BlockList, conversationId?: number) {
    this.assertConversationGuardrails(conversationId);
    this.api?.composer.commands.insertBlocks(blocks);
  }

  @action replaceBlocks(blocks: BlockList, conversationId?: number) {
    this.assertConversationGuardrails(conversationId);
    this.api?.composer.commands.replaceAllWithBlocks(blocks);
  }

  @action onReady(api: ComposerPublicAPI) {
    this.api = api;
    if (this.autofocus) {
      api.composer.commands.focus();
      this.autofocus = false;
    }

    if (this.args.onReady) {
      this.args.onReady({
        focus: this.focus,
        setPane: this.setPaneType,
        setActiveReplyPane: this.setActiveReplyPane,
        insertBlocks: this.insertBlocks,
        replaceBlocks: this.replaceBlocks,
        setActiveNotePane: this.setActiveNotePane,
        insertMacroActions: this.insertMacroActions,
        api: this.api,
        aiAssistCompletion: this.aiAssistCompletion,
      });
    }
  }

  @action onChange(blocksDoc: BlocksDocument) {
    this.didStartTyping = true;
    this.args.onTyping?.(this.panes.activePane.type);
    if (this.showSmartReplyToolbar) {
      this.dismissToolbar();
    }

    if (this.aiAssistApi.isActionsToolbarVisible && !this.aiAssistApi.isWorking) {
      this.aiAssistApi.onHideToolbar();
    }

    if (this.args.isSideConversationComposer) {
      this.panes.setActivePaneDocBlock(blocksDoc);
    } else {
      this.panes.setPaneBlocksDocAndStore(this.panes.activePane, blocksDoc);
    }

    this.updateRepliedToPartOnBlocksChange(blocksDoc);

    if (ENV.environment !== 'test') {
      throttleTask(this, '_trackUserTyping', 10 * ENV.APP._1S);
    }
    this.args.onChange?.();
  }

  _trackUserTyping() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'input',
      object: 'composer',
      place: 'inbox',
      section: 'respond',
      composer_type: this.panes.activePane?.type?.toLowerCase(),
      inbox_type: this.inboxState.activeInbox?.type,
      conversation_id: this.inboxState.activeConversationId,
    });
  }

  @action onComposerFocus() {
    this.isComposerFocused = true;

    if (this.showSmartReplyToolbar) {
      this.dismissToolbar();
    }
  }

  @action onComposerBlur() {
    this.isComposerFocused = false;
    this.args.onBlur && this.args.onBlur();
  }

  @action onRecipientSelectorBlur() {
    this.hasOpenedRecipientSelector = false;
    this.recipientSelectorAutofocus = false;
    if (this.updatedParticipants.length < 1 && this.args.conversation.participantSummaries) {
      this.onRecipientChange(this.args.conversation.participantSummaries);
      this.onRecipientManagerChange(this.defaultRecipientsList);
    }
  }

  @action onPanePickerOptionClick(pane: ComposerPane, hideDropdownOverlay: Function) {
    this.setPane(pane);
    hideDropdownOverlay();
  }

  @action setPaneType(type: ComposerPaneType) {
    if (type === ComposerPaneType.Note) {
      this.setPane(this.panes.notePane);
    } else {
      this.setPane(this.panes.replyPane);
    }
  }

  @action insertTicketCardBlock(ticketType: TicketType) {
    let { id, name } = ticketType;
    let block = {
      icon_url: '',
      state: 'interactive',
      text: this.intl.t('inbox.conversation-reply-composer.ticket-card-block-text'),
      ticket_type_id: id,
      ticket_type_title: name,
      title: this.intl.t('inbox.conversation-reply-composer.ticket-card-block-title'),
      type: 'createTicketCard',
    } as Block;
    this.api?.composer.commands.insertCreateTicketCard(block);
    this.api?.composer.outputNewBlocksDoc();
  }

  @action insertQuoteReply(metadata: Array<Block>) {
    if (metadata) {
      let transformedBlocks = this.transformBlocksToQuoteReplyFormat(metadata);
      this.api?.composer.commands.focus();
      this.api?.composer.commands.insertBlocks(transformedBlocks);
      this.api?.composer.commands.selectAll();
      this.api?.composer.commands.insertNewlineAndSelect();
      this.api?.composer.commands.selectAll();
      this.api?.composer.commands.insertNewlineAndSelect();
    }
  }

  // Add quote marks around paragraph blocks, make the text italic and insert a line break
  // TODO: Update styling to look like a quote reply. May involve transforming to new block type
  transformBlocksToQuoteReplyFormat(blocks: Array<Block>) {
    let transformedBlocks: Array<Block> = [];
    let firstParagraphBlock = true;
    let paragraphBlocks = blocks.filter((block) => block.type === 'paragraph');
    let lastParagraphBlock = paragraphBlocks[paragraphBlocks.length - 1];

    blocks.forEach((block: Block) => {
      if (block.type === 'paragraph') {
        let newBlock = Object.assign({}, block);
        // We need to strip existing italics so that the quote reply is consistently italic
        let newBlockText = newBlock.text.replaceAll('<i>', '').replaceAll('</i>', '');

        if (firstParagraphBlock && block === lastParagraphBlock) {
          newBlockText = `<i>"${newBlockText}"</i>`;
          firstParagraphBlock = false;
        } else if (firstParagraphBlock && block !== lastParagraphBlock) {
          newBlockText = `<i>"${newBlockText}</i>`;
          firstParagraphBlock = false;
        } else if (block === lastParagraphBlock) {
          newBlockText = `<i>${newBlockText}"</i>`;
        } else {
          newBlockText = `<i>${newBlockText}</i>`;
        }

        newBlock.text = newBlockText;
        transformedBlocks.push(newBlock);
      } else {
        // no transformations needed for non-paragraph blocks
        let newBlock = Object.assign({}, block);
        transformedBlocks.push(newBlock);
      }
    });

    return transformedBlocks;
  }

  @action showUnsupportedTicketBanner() {
    this.snackbar.notifyError(this.intl.t('inbox.composer.tickets.not-supported-in-sdk'));
  }

  @action setPane(pane: ComposerPane, opts?: { source: SetPaneSource }) {
    if (pane.type === ComposerPaneType.Reply && !this.canReplyToInbound) {
      return;
    }
    this.panes.setActivePane(pane);
    this.autofocus = true;
    this.config = this.buildConfig(this.panes.activePane);

    // When switching panes via command-K, an event is already sent, so we don't
    // want to send another one.
    if (opts?.source !== 'command_k') {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'selected',
        object: this.notePaneIsActive ? 'compose_note' : 'compose_reply',
        section: 'composer',
        place: 'inbox',
        source: opts?.source,
      });
    }
  }

  @action onComposerUpdate() {
    if (this.autofocus) {
      this.api?.composer.commands.focus();
    }
  }

  @action insertCompletion(event: KeyboardEvent) {
    let target = event.target as Element;
    if (!target.getAttribute || target.getAttribute('contentEditable') !== 'true') {
      return;
    }
    let completion = this.completion;
    if (completion.length === 0) {
      return;
    }
    let prosemirrorSelection = this.api?.composer.state.prosemirrorState.selection;
    if (!prosemirrorSelection) {
      return;
    }
    let cursorPosition = prosemirrorSelection.to;
    let afterPosition = prosemirrorSelection.$to.after();
    if (!prosemirrorSelection.empty || cursorPosition !== afterPosition - 1) {
      return;
    }
    event.preventDefault();
    let composerIsEmpty = this.composerIsEmpty;
    this.insertBlocks(completion, this.args.smartReply?.conversationId);
    if (this.session.workspace.isFeatureEnabled('inbox2-smart-reply-toolbar')) {
      this.showSmartReplyToolbar = true;
      this.api?.composer.commands.highlightAll();
      this.api?.composer.disableFormatting();
    }
    this.intercomEventService.trackAnalyticsEvent({
      action: 'insert',
      section: 'composer',
      composer_is_empty: composerIsEmpty,
      conversation_id: this.args.conversation.id,
      last_part_entity_id: this.args.conversation.lastPart?.entityId,
      last_part_entity_type: this.args.conversation.lastPart?.entityType,
      characters_count: this.insertedSuggestedContentLength,
      ...this.args.smartReply?.analyticsData,
    });
  }

  @action removeCompletion(reason?: string, reasonDetail?: string) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'rejected',
      section: 'composer',
      conversation_id: this.args.conversation.id,
      last_part_entity_id: this.args.conversation.lastPart?.entityId,
      last_part_entity_type: this.args.conversation.lastPart?.entityType,
      characters_count: this.insertedSuggestedContentLength,
      reason,
      reason_detail: reasonDetail,
      ...this.args.smartReply?.analyticsData,
    });
    this.panes.replyPane.clear();
    this.dismissToolbar();
  }

  @action acceptCompletion() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'accepted',
      section: 'smart_reply_toolbar',
      conversation_id: this.args.conversation.id,
      last_part_entity_id: this.args.conversation.lastPart?.entityId,
      last_part_entity_type: this.args.conversation.lastPart?.entityType,
      characters_count: this.insertedSuggestedContentLength,
      ...this.args.smartReply?.analyticsData,
    });
    this.dismissToolbar();
  }

  @action dismissToolbar() {
    this.api?.composer.enableFormatting();
    this.api?.composer.commands.clearHighlights();
    this.showSmartReplyToolbar = false;
  }

  @action async useMacro(macro: Macro) {
    let renderedMacro = await macro.render(
      this.session.workspace.id,
      this.updatedParticipants.length > 1,
      this.args.conversation.id?.toString(),
      this.updatedParticipants.get(0)?.id?.toString(),
    );

    this.insertMacroActions(renderedMacro.actions);

    this.api?.composer.commands.focus();
    if (renderedMacro.blocks.length > 0) {
      // Macro is conversation-agnostic so it is OK to pass current composer conversationId
      this.insertBlocks(renderedMacro.blocks, this.conversationId);
    }
    await this.savedReplyInsertionsService.recordInsertion(
      this.session.workspace,
      this.session.teammate,
      macro,
      this.args.conversation.id,
    );
  }

  @action onDeleteMacroAction(macroAction: MacroAction) {
    this.panes.activePane.macroActions = this.panes.activePane.macroActions.without(macroAction);
    this.panes.storeMacroActions(this.panes.activePane);
  }

  @action async insertWhatsappTemplate(_id: string, _context: unknown, metadata: any) {
    this.setPane(this.panes.replyPane);
    this.insertedWhatsappTemplate = metadata.template;
  }

  @action clearWhatsappTemplate() {
    this.insertedWhatsappTemplate = undefined;
  }

  @action onWhatsappBannerButtonClick() {
    this.commandK.registerAndShow({
      actionID: 'insert-whatsapp-template',
      onSelect: this.insertWhatsappTemplate,
    });
  }

  @action onDelineatingRepliesBannerButtonClick() {
    let id = this.args.conversation.latestSocialConversationId;
    if (!id) {
      return;
    }
    this.router.transitionTo(
      'inbox.workspace.inbox.inbox.conversation.conversation',
      InboxCategory.Shared,
      InboxType.All,
      id,
    );
  }

  @action onMergedSecondaryRepliesBannerButtonClick() {
    let primaryConversationId = this.args.conversation.mergedIntoConversationId;
    if (!primaryConversationId) {
      return;
    }
    this.router.transitionTo(
      'inbox.workspace.inbox.conversation.conversation',
      primaryConversationId,
    );
  }

  maybeSetSideConversationContext() {
    if (this.args.isSideConversationComposer) {
      this.commandK.setActivationContext(DisplayContext.SideConversation);
    }
  }

  @action onClickBolt() {
    this.maybeSetSideConversationContext();
    this.commandK.show();

    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      section: 'composer',
      object: 'command_k',
      conversation_id: this.args.conversation.id,
    });
  }

  get placeholder() {
    return this.intl.t('inbox.composer.reply.placeholder', {
      modifierKey: this.modifierKey,
    });
  }

  get composerIsEmpty() {
    let isBodyEmpty = replyChannelIsEmail(this.panes.replyPane.channel)
      ? this.blocksDoc.excludingAttachmentListBlock.blocks.length === 0
      : this.blocksDoc.blocks.length === 0;

    return isBodyEmpty && this.panes.activePane.macroActions.length === 0;
  }

  get suggestedBlocks() {
    return this.args.smartReply?.blocks;
  }

  get suggestedMacro() {
    return this.copilotQuestionSuggestions.visibleMacroSuggestion;
  }

  get showSuggestedMacro() {
    return (
      this.suggestedMacro &&
      this.suggestedMacro.macro.blocks.length > 0 &&
      this.currentComposerBlocks?.length === 0
    );
  }

  get renderableSuggestedBlocks() {
    // to address https://github.com/intercom/intercom/issues/244201
    // we replicate the logic in https://github.com/intercom/intercom/blob/8cfd4b47e97da6f411b60148786ce6aeb85690ff/app/lib/blocks/rendering/empty_line_transformation.rb
    // a more longer term fix would be to move the "autocomplete" experience into prosemirror itself
    // see https://github.com/intercom/embercom-prosemirror-composer/pull/1100
    return this.args.smartReply?.blocks.map((block) => {
      if (block.type === 'paragraph' && block.text === '') {
        return {
          type: 'paragraph',
          text: ' ',
          class: (block as Paragraph).class,
        };
      } else {
        return block;
      }
    });
  }

  get suggestedBlocksIsPending() {
    return this.args.smartReply?.isPending;
  }

  get showSuggestedBlocks() {
    return this.completion.length > 0;
  }

  get smartReplySources() {
    return this.args.smartReply?.sources;
  }

  @action didShowSmartReply(_: Element) {
    if (this.args.smartReply && !this.args.smartReply.viewed) {
      this.args.smartReply.viewed = true;
      this.intercomEventService.trackAnalyticsEvent({
        action: 'view',
        section: 'composer',
        conversation_id: this.args.conversation.id,
        last_part_entity_id: this.args.conversation.lastPart?.entityId,
        last_part_entity_type: this.args.conversation.lastPart?.entityType,
        characters_count: this.shownSuggestedContentLength,
        ...this.args.smartReply?.analyticsData,
      });
    }
  }

  get completion(): BlockList {
    if (!this.suggestedBlocks) {
      return [];
    }
    if (this.panes.activePane !== this.panes.replyPane) {
      return [];
    }
    let blocks = this.panes.activePane.blocksDoc.blocks;
    // test whether the blocks typed in are a prefix of the suggestion.
    if (blocks.length > this.suggestedBlocks.length) {
      return [];
    }
    if (blocks.length === 0) {
      return this.suggestedBlocks;
    }
    // check for completed blocks
    for (let i = 0; i < blocks.length - 1; i++) {
      let b = blocks[i];
      let s = this.suggestedBlocks[i];

      if (s.type !== 'paragraph' || b.type !== s.type || s.text !== b.text) {
        return [];
      }
    }

    // check for most recent block
    let currentBlock = blocks[blocks.length - 1];
    let restOfCurrentBlock = { ...this.suggestedBlocks[blocks.length - 1] };
    if (
      restOfCurrentBlock.type !== 'paragraph' ||
      restOfCurrentBlock.type !== currentBlock.type ||
      !restOfCurrentBlock.text.startsWith(currentBlock.text)
    ) {
      return [];
    }

    // pull out remaining text to complete the current block
    restOfCurrentBlock.text = restOfCurrentBlock.text.substring(currentBlock.text.length);
    if (restOfCurrentBlock.text === '' && blocks.length === this.suggestedBlocks.length) {
      return [];
    }

    let rest: BlockList = [];
    rest.push(restOfCurrentBlock);

    // Now grab the rest of the suggested blocks to complete the suggestion
    for (let i = blocks.length; i < this.suggestedBlocks.length; i++) {
      rest.push(this.suggestedBlocks[i]);
    }
    return rest;
  }

  get listPanes(): ComposerPane[] {
    return this.canReply ? this.panes.listPanes : [this.panes.notePane];
  }

  get canReply(): boolean {
    if (this.session.showLightInbox) {
      return false;
    }

    return this.args.conversation.isReplyable;
  }

  get canReplyToInbound(): boolean {
    if (this.session.skipCanReplyToInboundCheck) {
      return true;
    }

    return !this.args.conversation.isInboundConversation;
  }

  get supportedChannels() {
    if (this.args.supportedReplyChannels) {
      return this.args.supportedReplyChannels;
    }
    if (this.args.forwardedPartId) {
      return [EmailChannel];
    }
    return this.panes.replyPane.supportedChannels;
  }

  get replyPaneIsActive() {
    return this.panes.activePane === this.panes.replyPane;
  }

  get notePaneIsActive() {
    return this.panes.activePane === this.panes.notePane;
  }

  @action
  setActiveReplyPane() {
    if (this.notePaneIsActive && this.canReply && this.canReplyToInbound) {
      this.panes.setActivePane(this.panes.replyPane);
    }
    return this.panes.activePane?.blocksDoc.blocks;
  }

  @action
  setActiveNotePane(): BlockList {
    this.panes.setActivePane(this.panes.notePane);
    return this.panes.activePane?.blocksDoc.blocks;
  }

  @action insertMacroActions(macroActions: MacroAction[]) {
    let existingMacroActions = this.panes.activePane.macroActions;

    this.panes.activePane.macroActions = [...existingMacroActions, ...macroActions];
  }

  get isReplyChannelSms() {
    return this.panes.replyPane.channel?.type === ReplyChannelType.SMS;
  }

  get isReplyChannelEmail() {
    return this.panes.replyPane.channel?.type === ReplyChannelType.Email;
  }

  get isReplyChannelWhatsapp() {
    return this.panes.replyPane.channel?.type === ReplyChannelType.Whatsapp;
  }

  get isSocialChannel() {
    return SOCIAL_CHANNELS.includes(this.args.replyChannel);
  }

  get outboundWhatsappConversation() {
    return this.isNewConversation && this.isReplyChannelWhatsapp;
  }

  get restrictNewSmsConversation() {
    return this.isNewConversation && this.isReplyChannelSms && !isEmpty(this.args.recipients);
  }

  get showLargeComposer() {
    return this.args.canBeLarge && this.panes.activePane.channel?.type === ReplyChannelType.Email;
  }

  get shouldHideRecipientList() {
    return !this.isComposerFocused || this.notePaneIsActive || this.hasOpenedRecipientSelector;
  }

  get shouldHideRecipientSelector() {
    return (
      this.notePaneIsActive ||
      (!this.hasOpenedRecipientSelector &&
        (!this.hasDraftParticipantChanges || this.isComposerFocused))
    );
  }

  private get latestSocialConversationId(): number | null | undefined {
    // restrict user from creating a new conversation if there is an open SMS onversation
    let latestOpenSocialConversationId = this.latestSocialConversationIdLoader.value;
    // Nolaneo – this linter error seems genuine: why are we mutating state in this getter?
    // eslint-disable-next-line
    this.canCreateNewSmsConversation = !latestOpenSocialConversationId;
    return latestOpenSocialConversationId;
  }

  private latestSocialConversationIdLoader = trackedFunction(this, async () => {
    if (!this.restrictNewSmsConversation) {
      return;
    }

    let [recipient] = this.args.recipients!;
    if (!recipient) {
      return;
    }

    return await this.inboxApi.getLatestSocialConversationId(recipient.id, Channel.SMS);
  });

  // if there is an open sms conversation, re-route to that conversation
  @action onSmsDelineation() {
    if (!this.latestSocialConversationId) {
      return;
    }
    this.router.transitionTo(
      'inbox.workspace.inbox.conversation.conversation',
      this.latestSocialConversationId,
    );
  }

  // allow user to override sms restricted banner when the user has an open SMS conversation to create a new one
  @action allowNewSmsConversation() {
    this.canCreateNewSmsConversation = true;
  }

  // check if sms usage limits have been reached
  get smsUsageLimitsReached() {
    if (this.replyPaneIsActive && this.isReplyChannelSms) {
      let usageLimitStatus = this.smsUsageLimitStatusLoader.value;
      return usageLimitStatus === USAGE_LIMIT_STATUS.ALL_SENDING_BLOCKED;
    }
    return false;
  }

  private smsUsageLimitStatusLoader = trackedFunction(this, async () => {
    return await this.inboxApi.getSmsUsageLimitsStatus(Channel.SMS);
  });

  get restrictSmsCountry() {
    let recipient;

    if (this.replyPaneIsActive && this.isReplyChannelSms) {
      if (this.isNewConversation && !isEmpty(this.args.recipients)) {
        [recipient] = this.args.recipients!;
      } else {
        recipient = this.args.conversation.firstParticipant;
      }
    }

    return recipient?.phone && !this.allowedSmsCountries?.includes(recipient.phoneCountry || '');
  }

  get twitterIntegrationDisabled() {
    return (
      this.args.replyChannel === Channel.Twitter &&
      !this.session.workspace.isFeatureEnabled('enable-twitter-integration')
    );
  }

  get isMultiParticipantChannel() {
    // allow participant management for certain channels, and null initiated tickets
    return (
      this.replyChannelIsMultiParticipant(this.panes.replyPane.channel) ||
      (this.args.replyChannel === Channel.Unknown && this.args.conversation.isTicket)
    );
  }

  get usersInToField() {
    let userSummaries = this.ccEnabled ? this.recipientsList.all : this.updatedParticipants;
    if (isPresent(this.replyToData)) {
      this.replyToData.forEach((replyTo) => {
        let replyToParticipant = userSummaries.find((user) => user.id === replyTo.id);
        if (replyToParticipant) {
          userSummaries = userSummaries.without(replyToParticipant);
          userSummaries.unshift(replyTo);
        }
      });
    }
    return userSummaries;
  }

  get conversationUserId() {
    return this.args.conversation.userSummary?.id;
  }

  get replyToData() {
    let userParts =
      this.args.conversation.committedParts.filter(
        (part) => part.createdByUser && part.renderableType === RenderableType.UserEmailComment,
      ) || [];
    return (
      userParts
        // @ts-ignore
        .filter((part) => isPresent(part?.renderableData.replyToAddress))
        .map((part) => {
          let renderableData = part.renderableData as UserEmailComment;
          return new UserSummary(renderableData.userSummary?.id, renderableData.replyToAddress);
        })
    );
  }

  get shouldShowSmsSegmentInfo() {
    return this.isReplyChannelSms && this.replyPaneIsActive && !this.composerIsEmpty;
  }

  get isUploading() {
    return this.api?.composer.state.isUploading ?? false;
  }

  get willSnoozeByMacroAction(): boolean {
    let actions = this.panes.activePane.macroActions;
    return actions.any((action) => action.type === 'snooze-conversation');
  }

  get willCloseByMacroAction(): boolean {
    let actions = this.panes.activePane.macroActions;
    return actions.any((action) => action.type === 'close-conversation');
  }

  get canAddTicket() {
    return (
      this.replyPaneIsActive &&
      this.isChatCurrentChannel &&
      !this.args.conversation.isTicket &&
      this.args.conversation.canShareLinkedTicket
    );
  }

  get blocksToSend() {
    if (this.isWhatsappCurrentChannel && this.insertedWhatsappTemplate) {
      return this.insertedWhatsappTemplate.blocksWithParameters;
    }

    let composerBlocks = this.blocksDoc.blocks;
    // if email history was expanded, remove the history block from the composer blocks as it's sent as a separate parameter
    if (this.emailConversationReply && this.historyExpanded) {
      composerBlocks = this.removeHistoryBlock(composerBlocks);
    }
    return composerBlocks;
  }

  get showSendAndSetState() {
    return this.args.conversation.isTicket && !this.willCloseByMacroAction;
  }

  get currentTicketCustomState(): TicketCustomState {
    return this.ticketStateService.getTicketCustomStateById(
      this.args.conversation.ticketCustomStateId,
    );
  }

  get currentTicketState() {
    if (!this.args.conversation) {
      return undefined;
    }

    return this.currentTicketCustomState.systemState;
  }

  get showSendAndCloseHint(): boolean {
    let hasSeenEnoughTimes = this.sendAndCloseHintTimestamps.length >= 3;
    let hasSeenInLastDay =
      !isEmpty(this.sendAndCloseHintTimestamps) &&
      this.timestampIsInLastDay(last(this.sendAndCloseHintTimestamps)!);

    return (
      !!this.args.canShowSendAndCloseHint &&
      this.replyPaneIsActive &&
      this.didStartTyping &&
      !hasSeenEnoughTimes &&
      !hasSeenInLastDay
    );
  }

  private timestampIsInLastDay(timestamp: number) {
    return moment.unix(timestamp).isAfter(moment().subtract(1, 'day'));
  }

  // Evaluates whether the option to "Send and close" a conversation should be available,
  // either as the Send & close button or an item in the Send button dropdown.
  get showSendAndClose() {
    return !(this.willCloseByMacroAction || this.willSnoozeByMacroAction);
  }

  get sendNoteButtonType() {
    if (this.args.isTicketNotesComposer && !this.canSend) {
      return 'secondary';
    }

    return 'primary';
  }

  get sendNoteButtonLabel() {
    return this.intl.t('inbox.composer.add-note');
  }

  get disableSendNoteTooltip() {
    return this.args.isTicketNotesComposer && !this.canSend;
  }

  get showSendAndCloseButton() {
    if (this.actionsContainer && this.shortcutAndInsertersContainer) {
      // The inserters button is visible when the composer is focused or the inserters popover is open.
      // We need to take its width into account so that its visibility doesn't affect the Send & close button's visibility
      let insertersButtonAllowance =
        this.isComposerFocused || this.insertersPopoverOpen ? INSERTERS_BUTTON_WIDTH : 0;
      let remainingWidth =
        this.actionsContainer.clientWidth -
        this.shortcutAndInsertersContainer.clientWidth +
        insertersButtonAllowance;
      let isCompletelyNewConversation = this.isNewConversation && !this.args.forwardedPartId;

      return (
        this.showSendAndClose &&
        !isCompletelyNewConversation &&
        !this.args.conversation.isClosed &&
        remainingWidth > MINIMUM_SEND_AND_CLOSE_BUTTON_WIDTH
      );
    }

    return false;
  }

  get conversationInstance(): Conversation | undefined {
    return this.args.conversation instanceof Conversation && !this.args.conversation.isLoading
      ? this.args.conversation
      : undefined;
  }

  get lastTeammatePart(): RenderablePart | undefined {
    return this.conversationInstance?.humanAdminComments.sortBy('createdAt').lastObject;
  }

  get lastEndUserPart(): RenderablePart | undefined {
    return this.conversationInstance?.userComments.sortBy('createdAt').lastObject;
  }

  @action async send(options = { keyboardShortcutUsed: false, crossPost: false }) {
    let result = await this.executeSend(async () => {
      return await this.args.onSend(
        this.panes.activePane.type,
        this.blocksToSend,
        this.panes.activePane.macroActions,
        this.panes.activePane.channel?.type,
        this.replyData,
        this.participantData,
        options.crossPost,
        this.emailHistoryIdToSend,
      );
    });

    if (!result) {
      return;
    }

    if (!this.isNewConversation) {
      this.trackSend(options);
    }

    this.clearParticipantsStorage();
  }

  private isUnresolvedTicket() {
    return (
      this.args.conversation.isTicket &&
      this.args.conversation.ticketState !== TicketSystemState.Resolved
    );
  }
  @action async selectSendAndClose(options = { keyboardShortcutUsed: false }) {
    if (this.isUnresolvedTicket()) {
      this.sendAndCloseCmdK();
      return;
    }

    await this.sendAndClose(options);
  }

  @action sendAndCloseCmdK() {
    this.commandK.registerAndShow({
      actionID: 'resolve-and-close-ticket-state',
      context: {
        ticketType: (this.args.conversation as Conversation).ticketType,
        currentTicketState: this.currentTicketCustomState,
      },
      onSelect: this.handleResolvedStateChange,
    });
  }

  @action async handleResolvedStateChange(state: ConversationState.Closed | number) {
    switch (state) {
      case ConversationState.Closed:
        // Close conversaiton without resolving
        return await this.sendAndClose();
      default:
        return await this.resolveSendAndCloseWithCustomState(state);
    }
  }

  @action async resolveSendAndCloseWithCustomState(customStateId: number) {
    let newState = this.ticketStateService.getTicketCustomStateById(customStateId);
    // Update the ticket state to the selected custom state
    if (this.conversationInstance) {
      this.inboxState.changeTicketState(this.args.conversation as Conversation, newState);
    }

    await this.sendAndClose();
  }

  @action
  async sendAndClose(options = { keyboardShortcutUsed: false }) {
    let wasShowingSendAndCloseHint = this.showSendAndCloseHint;

    if (options.keyboardShortcutUsed) {
      this.onDismissSendAndCloseHint?.({ reason: 'send-and-close-keyboard-shortcut-used' });
    }

    let result = await this.executeSend(async () => {
      return await this.args.onSendAndClose!(
        this.blocksToSend,
        this.panes.activePane.macroActions,
        this.panes.activePane.channel?.type,
        this.replyData,
        this.participantData,
        this.emailHistoryIdToSend,
      );
    });

    if (!result) {
      return;
    }

    this.intercomEventService.trackAnalyticsEvent({
      action: 'close_replied',
      section: 'composer',
      object: 'conversation',
      conversation_id: this.args.conversation.id,
      layout_type: this.inboxState.activeConversationsView,
      shortcut_key: options.keyboardShortcutUsed,
      was_close_and_send_hint_visible: wasShowingSendAndCloseHint,
    });

    this.clearParticipantsStorage();
  }

  @action
  async sendAndSnooze(duration: DurationObject, options = { keyboardShortcutUsed: false }) {
    let result = await this.executeSend(async () => {
      return await this.args.onSendAndSnooze!(
        this.blocksToSend,
        this.panes.activePane.macroActions,
        duration,
        this.panes.activePane.channel?.type,
        this.replyData,
        this.participantData,
        this.emailHistoryIdToSend,
      );
    });

    if (!result) {
      return;
    }

    this.intercomEventService.trackAnalyticsEvent({
      action: 'snooze_replied',
      section: 'composer',
      object: 'conversation',
      conversation_id: this.args.conversation.id,
      snoozed_until: DurationType[duration.type],
      layout_type: this.inboxState.activeConversationsView,
      shortcut_key: options.keyboardShortcutUsed,
    });

    this.clearParticipantsStorage();
  }

  @action
  async sendAndSetState(ticketState: TicketCustomState, options = { keyboardShortcutUsed: false }) {
    let result = await this.executeSend(async () => {
      if (this.args.onSendAndSetState === undefined) {
        return;
      }

      return await this.args.onSendAndSetState(
        this.blocksToSend,
        this.panes.activePane.macroActions,
        ticketState,
        this.panes.activePane.channel?.type,
        this.replyData,
        this.participantData,
        this.emailHistoryIdToSend,
      );
    });

    this.intercomEventService.trackAnalyticsEvent({
      action: 'set_state_replied',
      section: 'composer',
      object: 'conversation',
      conversation_id: this.args.conversation.id,
      layout_type: this.inboxState.activeConversationsView,
      shortcut_key: options.keyboardShortcutUsed,
    });

    if (!result) {
      return;
    }

    this.clearParticipantsStorage();
  }

  @action handleSendHotkey(action: Function, event: KeyboardEvent, _kbEvent: any) {
    if (this.insertedWhatsappTemplate) {
      event.preventDefault();
      return action({ keyboardShortcutUsed: true });
    }
    if (!this.isComposerFocused) {
      return;
    }
    return this.handleEditorHotkey(() => action({ keyboardShortcutUsed: true }), event, _kbEvent);
  }

  @action handleSendAndCrossPostHotkey(action: Function, event: KeyboardEvent, _kbEvent: any) {
    return this.handleEditorHotkey(
      () => action({ keyboardShortcutUsed: true, crossPost: true }),
      event,
      _kbEvent,
    );
  }

  @action onEscape() {
    this.hasOpenedRecipientSelector = false;
    (document.activeElement as HTMLElement)?.blur();
  }

  @action onComposerClick(event: PointerEvent & { target: HTMLElement }) {
    let { target } = event;
    if (!target) {
      return;
    }

    // If the click was on a button, or a recipient selector, or a field that is
    // part of the outbound composer, we don't want to focus the composer.

    // We also avoid click events on the actual composer because it handles it itself. If
    // we capture its click and focus, that interferes with tap to click on some devices.
    let selectorsToIgnore = [
      'button',
      '[data-recipient-selector]',
      '[data-outbound-fields]',
      '[data-recipient-manager]',
    ];

    if (!(this.showSuggestedBlocks && this.renderableSuggestedBlocks)) {
      selectorsToIgnore.push('[data-conversation-reply-composer]');
    }

    let ignoreClick = selectorsToIgnore.any((selector) => Boolean(target.closest(selector)));
    if (!ignoreClick) {
      this.api?.composer.commands.focus();
    }

    if (target.closest('[data-conversation-reply-composer]') && this.showSmartReplyToolbar) {
      this.acceptCompletion();
    }
  }

  @action setChannel(
    channel: ReplyChannel,
    opts: { source?: SetPaneSource; fromStorage?: boolean } = {},
  ) {
    if (!this.isNewConversation || !this.panes.replyPane) {
      return;
    }

    this.panes.replyPane.channel = channel;
    this.args.onChannelChange?.(channel, opts.fromStorage ?? false);
    // update the config whenever the channel is changed, specifically for a new conversation from the inbox
    this.config = this.buildConfig(this.panes.activePane);
    storage.set(this.latestReplyChannelStorageKey, channel);
  }

  @action
  insertApp(actionData: MessengerCardBlock): void {
    this.selectedApp = undefined;
    this.api!.composer.commands.focus();
    this.api!.composer.commands.insertBlock(actionData);
    this.api!.composer.commands.insertNewlineAndSelect();
  }

  @action showRecipientSelector(): void {
    this.hasOpenedRecipientSelector = true;
    this.recipientSelectorAutofocus = true;
  }

  @action async aiAssist(action: AiAssistPromptKey | 'insert-summary' | 'add-summary' | 'ask-fin') {
    if (action === 'insert-summary') {
      return await this.insertSummary();
    } else if (action === 'add-summary') {
      return await this.addSummaryToConversation();
    } else if (action === 'ask-fin') {
      this.openCopilot();
    } else {
      return await this.replaceWithOpenAICompletion(action);
    }
  }

  get isAiAssistInProgress() {
    return this.aiAssistApi.isWorking;
  }

  @action aiAssistCompletion(promptKey: AiAssistPromptKey, blocks: BlockList) {
    return this.aiAssistApi.complete(
      promptKey,
      this.conversationId,
      blocks,
      {
        action: 'replace_selection',
        section: 'composer',
        object: 'composer',
        source: 'composer_toolbar',
      },
      this.conversationUserId,
    );
  }

  @action async replaceWithOpenAICompletion(promptKey: AiAssistPromptKey) {
    let userId = promptKey === 'tone' ? this.args.conversation.userSummary?.id : undefined;
    let blocks = await this.aiAssistApi.complete(
      promptKey,
      this.args.conversation.id,
      this.currentComposerBlocks,
      {
        action: 'replace',
        section: 'composer',
        object: 'composer',
      },
      userId,
    );

    this.api?.composer.commands.replaceAllWithBlocks(blocks);
  }

  @action async addSummaryToConversation() {
    if (!(this.args.conversation instanceof Conversation)) {
      return;
    }

    return await this.inboxState.addSummaryToConversation(this.args.conversation);
  }

  @action async insertSummary() {
    if (!(this.args.conversation instanceof Conversation)) {
      return;
    }

    this.setPane(this.panes.notePane);
    let result: { conversation_id?: number | undefined; summary: BlockList } | undefined;
    result = await this.aiAssistApi.generateSummary(
      this.args.conversation.id,
      this.args.conversation.isTicket,
    );
    if (!result?.summary) {
      return;
    }

    this.insertBlocks(result.summary, result.conversation_id);
  }

  @task
  *loadForwardingContext(forwardedPartId?: string): TaskGenerator<void> {
    try {
      let forwardingContext = yield forwardedPartId
        ? this.inboxApi.fetchForwardingContext(forwardedPartId)
        : undefined;

      if (forwardingContext?.blocks) {
        let parsedBlocks = forwardingContext.blocks.map((block: Block) =>
          this.handleHtmlContent(block),
        );

        if (this.args.setTitle) {
          this.args.setTitle(forwardingContext.subject);
        }
        let blocksDoc = new BlocksDocument(parsedBlocks);
        this.panes.setPaneBlocksDocAndStore(this.panes.activePane, blocksDoc);
      }

      this.loadingForwardingContext = false;
    } catch (error) {
      captureException(error);
      this.loadingForwardingContext = false;
      this.clearParticipantsStorage();
      this.notificationsService.notifyError(
        this.intl.t('inbox.notifications.failed-loading-forwarded-message'),
      );
      this.panes.replyPane.clear();
    }
  }

  handleHtmlContent(block: Block): Block {
    switch (block.type) {
      case 'html':
        return {
          type: 'paragraph',
          text: this.cleanUnallowedHtmlTags((block as Html).content),
          class: 'no-margin',
        };
      case 'paragraph':
      case 'heading':
      case 'subheading':
        return {
          ...block,
          text: this.cleanUnallowedHtmlTags((block as Paragraph).text),
        };
      case 'orderedList':
      case 'unorderedList':
        return {
          ...block,
          items: (block as List).items.map((i) => this.cleanUnallowedHtmlTags(i)),
        } as List;
      default:
        return block;
    }
  }

  cleanUnallowedHtmlTags(text: string): string {
    // 'html', 'head', 'body' are always present when parsing HTML content
    // list from app/lib/inbox/composer-config.ts:69
    let allowedInline = ['html', 'head', 'body', 'b', 'i', 'a', 'code', 'br'];

    let parser = new DOMParser();

    let doc = parser.parseFromString(text, 'text/html');

    let elements = doc.getElementsByTagName('*');
    for (let i = 0; i < elements.length; i++) {
      let tagName = elements[i].tagName.toLowerCase();
      if (!allowedInline.includes(tagName)) {
        return htmlToTextContent(text);
      }
    }
    return text;
  }

  private get modifierKey() {
    return platform.isMac ? '⌘' : 'Ctrl';
  }

  private get blocksDoc() {
    return trimWhitespaces(this.panes.activePane.blocksDoc);
  }

  private trackSend(options = { keyboardShortcutUsed: false }) {
    let { type } = this.panes.activePane;

    if (type === ComposerPaneType.Reply) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'replied',
        section: 'composer',
        object: 'conversation',
        conversation_id: this.args.conversation.id,
        layout_type: this.inboxState.activeConversationsView,
        shortcut_key: options.keyboardShortcutUsed,
        num_removed_participants: this.participantData?.removedParticipantIds.length,
        num_added_participants_existing_users: this.participantData?.newParticipantIds.length,
        num_added_participants_new_users: this.participantData?.newParticipantEmails.length,
      });
    } else if (type === ComposerPaneType.Note) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'added',
        section: 'composer',
        object: 'note',
        conversation_id: this.args.conversation.id,
        layout_type: this.inboxState.activeConversationsView,
        shortcut_key: options.keyboardShortcutUsed,
      });
    }
  }

  get prevRecipients() {
    let headers = this.latestCommentPart?.renderableData?.emailMetadata?.headerAddresses;
    let participants = [headers?.to || [], headers?.cc || [], headers?.from || []].flat();
    return participants.map((x) => x.email?.toLowerCase());
  }

  // If a participant that has been removed by an end-user is about to receive this email, ask the teammate to confirm.
  // The modal will show the participants who were removed by the end-user and the new participants who were added by the teammate in that reply.
  get evaluateRecipientsConfirmationModalParams() {
    let params = { show: false, changedParticipants: [] };
    if (!this.session.workspace.isFeatureEnabled('channels-show-confirm-recipients-modal')) {
      return params;
    }
    if (!this.emailConversationReply) {
      return params;
    } else if (this.latestCommentPart?.renderableType !== RenderableType.UserEmailComment) {
      return params;
    }

    let previousReplyRecipients = this.prevRecipients;
    // email addresses currently listed in the composer
    let addressesInTheComposer = this.getUpdatedParticipants().map((x) => x.email?.toLowerCase());
    // email addresses of the participants of this conversation
    let addressesOfExistingParticipants = this.args.conversation.participantSummaries.map((x) =>
      x.email?.toLowerCase(),
    );
    // the intersection of the 2 sets above, ie:
    // emails listed in the composer, with any users that were newly added by the teammate filtered out
    let participantsInTheComposer = addressesInTheComposer.filter((x) =>
      addressesOfExistingParticipants.includes(x),
    );

    // participants in the composer who did not receive the last user reply (i.e. were removed by the end-user)
    let participantsRemovedByEndUser = participantsInTheComposer.filter(
      (x) => !previousReplyRecipients.includes(x),
    );

    if (isEmpty(participantsRemovedByEndUser)) {
      return params;
    }

    let newParticipants = addressesInTheComposer.filter(
      (x) => !addressesOfExistingParticipants.includes(x),
    );

    let changedParticipants = participantsRemovedByEndUser.concat(newParticipants);

    return { show: true, changedParticipants };
  }

  get ccEnabled() {
    return this.session.workspace.isFeatureEnabled('team-channels-cc');
  }

  private async executeSend(fn: () => unknown | SendResult) {
    if (!this.canSend) {
      return false;
    }

    try {
      await this.showRecipientsConfirmationModel();

      let conversationId = this.conversationId;
      let pessimistic = this.args.pessimisticClearOnSend;
      let sendRequestPromise = fn();

      // the new conversation page waits for the send request to complete before clearing the composer
      // whereas everywhere else we want to optimistically clear and handle failure with retries
      if (pessimistic) {
        let result = (await sendRequestPromise) as SendResult;
        if (result?.errors?.length) {
          throw new Error();
        }
      }

      this.preprocessExecuteSend(conversationId);

      if (!pessimistic) {
        let result = (await sendRequestPromise) as any;
        if (result?.errors?.length) {
          throw new Error();
        }
      }

      // "Reply" requests to existing conversations don't return an error, so we check the
      // ConversationService for an update. This is run after the request is sent, so we can
      // get the failed status from there.
      if (this.args.conversation.id) {
        let lastObject = this.conversationUpdates.updatesFor(this.args.conversation.id).lastObject;
        if (lastObject?.isFailed) {
          throw new Error();
        }
      }

      this.args.onSendComplete?.();
      this.resetRecipientsListToDefault();

      return true;
    } catch (err) {
      return false;
    }
  }

  get showTooltipDataAttribute() {
    return (
      this.args.location === ComposerLocation.ConversationPage &&
      this.shouldHideRecipientSelector &&
      !this.notePaneIsActive
    );
  }

  get latestReplyChannelStorageKey() {
    return `latest-reply-channel-${this.session.workspace.id}`;
  }

  get latestReplyChannel() {
    return storage.get(this.latestReplyChannelStorageKey);
  }

  get latestUpdatedParticipants() {
    return getLatestUpdatedParticipants(storage, this.session.workspace.id, this.conversationId);
  }

  preprocessExecuteSend(conversationId: number | undefined) {
    this.trackEmailHistoryRemoval();
    this.trackEmailRecipientUsage();
    this.panes.replyPane.clear();
    this.panes.notePane.clear();
    this.clearWhatsappTemplate();

    this.panes.deletePaneBlocksDoc(this.panes.replyPane, conversationId);
    this.panes.deletePaneBlocksDoc(this.panes.notePane, conversationId);
    this.panes.deletePaneMacroActions(this.panes.replyPane, conversationId);
    this.panes.deletePaneMacroActions(this.panes.notePane, conversationId);
    this.api?.composer.commands.focus();
    this.clearEmailHistoryParams();
  }

  async showRecipientsConfirmationModel() {
    // Show the modal if necessary. If they hit cancel, early return
    // If they hit confirm, continue with the method and send the e-mail
    // if end user has removed a participant, warn the teammate when they hit send
    let recipientsModalParams = this.evaluateRecipientsConfirmationModalParams;
    if (!recipientsModalParams.show) {
      return;
    }

    this.intercomEventService.trackAnalyticsEvent({
      action: 'shown',
      object: 'confirm_recipients_modal',
      section: 'composer',
      place: 'inbox',
      conversation_id: this.conversationId,
    });

    let options = {
      title: this.intl.t('inbox.composer.confirm-recipients-modal.title'),
      bodyComponentName: 'inbox2/manage-participants/recipients-confirmation-body',
      confirmButtonText: this.intl.t('inbox.composer.confirm-recipients-modal.confirm-send'),
      primaryButtonType: 'primary',
      secondaryButtonType: 'secondary',
      confirmContext: { changedRecipients: recipientsModalParams.changedParticipants },
    };

    let confirmed = await this.intercomConfirmService.confirm(options);
    if (!confirmed) {
      // Error is handled in the executeSend.
      throw new Error();
    }
  }

  @action filterRecipientsList(element: HTMLElement) {
    let hidden = 0;
    let childrenArray = Array.from(element.children);
    if (childrenArray.length === 0) {
      return;
    }
    let mainLineBottom = childrenArray[0].getBoundingClientRect().bottom;
    childrenArray.forEach((child: HTMLElement) => {
      let rect = child.getBoundingClientRect();
      if (rect.bottom !== mainLineBottom) {
        child.style.display = 'none';
        hidden++;
      }
    });
    this.nrHiddenRecipients = hidden;
  }

  get showSendSeparatelyToggle() {
    if (this.args.isSideConversationComposer) {
      return false;
    }

    return (
      this.isNewConversation &&
      replyChannelIsEmail(this.panes.replyPane.channel) &&
      this.updatedParticipants.length > 1
    );
  }

  get disableSendSeparatelyToggle() {
    return this.recipientsList.cc.length > 0;
  }

  private clearParticipantsStorage() {
    storage.remove(
      this.latestUpdatedParticipantsStorageKey(this.session.workspace.id, this.conversationId),
    );
  }

  private clearRecipientsStorage() {
    storage.remove(
      this.latestUpdatedRecipientsListStorageKey(this.session.workspace.id, this.conversationId),
    );
  }

  get pinnedInserterIds() {
    return this.session.workspace.pinnedComposerInserters.value ?? [];
  }

  get showSendAndCloseButtonPinned() {
    return this.session.workspace.pinnedComposerSendCloseButton.value ?? true;
  }

  get allInserters(): InserterDetail[] {
    let inserters = [...COMPOSER_INSERTERS];
    if (this.messengerApps.toursApp && !this.args.isTicketNotesComposer) {
      inserters.push(makeProductToursInserter());
    }
    if (this.hasAIAssistEnabled) {
      inserters.push(makeAiAssistInserter());
    }

    if (this.canDisplayCopilotSuggestionsInserter) {
      inserters.push(makeCopilotSuggestionsInserter());
    }

    if (this.session.workspace.hasAccessToWorkflows) {
      inserters.push(makeTriggerWorkflowInserter());
    }

    if (this.session.workspace.isFeatureEnabled('inbox-composer-tables')) {
      inserters.push(makeTableInserter());
    }

    return inserters;
  }

  get canDisplayCopilotSuggestionsInserter() {
    return this.session.isCopilotEnabled && this.conversationCanHaveSuggestions;
  }

  get inserters(): InserterDetail[] {
    return this.allInserters.filter((i) => {
      if (this.session.showLightInbox && !COLLABORATOR_SEAT_INSERTER_ID_ALLOW_LIST.has(i.id)) {
        return false;
      }

      if (
        i.id === 'insert-article' &&
        (this.notePaneIsActive || !this.articlesApi.isArticleInserterInstalled)
      ) {
        return false;
      }
      return lookupChannelSupportedType(this.args.replyChannel, i.type);
    });
  }

  get conversationCanHaveSuggestions() {
    return this.args.conversation.isTicket
      ? (this.args.conversation as Conversation).isCustomerTicket
      : !this.isNewConversation;
  }

  @action useInserter(inserter: InserterDetail, opts: { source: string }) {
    // For copilot suggestions there's no cmd+k option to open
    if (inserter.id === 'copilot-suggestions') {
      return;
    }

    if (inserter.id === 'table') {
      // TODO - move to cmd+k action the same as other inserters
      this.api?.composer.commands.focus();
      this.api?.composer.commands.insertBlock({
        type: 'table',
        rows: [
          {
            cells: [
              {
                content: [{ type: 'paragraph', text: '' }],
              },
              {
                content: [{ type: 'paragraph', text: '' }],
              },
            ],
          },
          {
            cells: [
              {
                content: [{ type: 'paragraph', text: '' }],
              },
              {
                content: [{ type: 'paragraph', text: '' }],
              },
            ],
          },
        ],
      });
      return;
    }

    this.maybeSetSideConversationContext();
    this.activeInserter = { id: inserter.id, source: opts.source };
    this.commandK.findAndShow(inserter.id, () => (this.activeInserter = undefined), opts);
  }

  @action async updatePinnedInserters(ids: InserterId[]) {
    let oldValue = [...this.pinnedInserterIds];

    try {
      this.session.workspace.pinnedComposerInserters.update(ids);
      await this.session.updateTeammate({ visible_composer_inserters: ids });
    } catch (err) {
      this.snackbar.notifyError(this.intl.t('inbox.composer.inserters.error-pinning-inserter'));
      this.session.workspace.pinnedComposerInserters.update(oldValue);
    }
  }

  @action async toggleSendAndCloseButtonPinned(item: any) {
    let isPinned = item.itemPinned;

    try {
      this.session.workspace.pinnedComposerSendCloseButton.update(!isPinned);
      await this.session.updateTeammate({
        visible_composer_send_close: isPinned ? [] : ['send-and-close'],
      });
    } catch (err) {
      this.snackbar.notifyError(
        this.intl.t('inbox.composer.send-close-snooze.error-pinning-send-close-button-pinned'),
      );
      this.session.workspace.pinnedComposerSendCloseButton.update(isPinned);
    }
  }

  @action toggleInsertersPopover(open?: boolean) {
    this.insertersPopoverOpen = open ?? !this.insertersPopoverOpen;
  }

  @task
  *makeTicketVisible() {
    if (!(this.args.conversation instanceof Conversation)) {
      return;
    }

    try {
      yield this.inboxApi.shareTicketWithUser(this.args.conversation.id);
      this.showTicketVisibilityModal = false;
      this.args.conversation.visibleToUser = true;
      this.inboxState.trigger(InboxEvents.TicketVisibilityUpdated, this.args.conversation);
    } catch {
      this.snackbar.notifyError(
        this.intl.t('inbox.conversation-header.ticket-attributes-tab.make-ticket-visible-error'),
      );
    }
  }

  @action onDismissSendAndCloseHint({ reason: _reason }: { reason?: string }) {
    this.sendAndCloseHintTimestamps = [...this.sendAndCloseHintTimestamps, moment().unix()];

    this.args.onDismissSendAndCloseHint?.();
  }

  private get sendAndCloseHintTimestamps() {
    return storage.get(SEND_AND_CLOSE_HINT_TIMESTAMPS) ?? [];
  }

  private set sendAndCloseHintTimestamps(value: number[]) {
    storage.set(SEND_AND_CLOSE_HINT_TIMESTAMPS, value);
  }

  get emailConversationReply() {
    return (
      !this.isNewConversation &&
      replyChannelIsEmail(this.panes.replyPane.channel) &&
      this.replyPaneIsActive
    );
  }

  /***
   * Email history in the composer
   */

  private get workspaceId() {
    return this.session.workspace.id;
  }
  private get historyExpandedStorageKey() {
    return `email-conversation-history-expanded-${this.workspaceId}-${this.conversationId}`;
  }

  private get historyMetadataStorageKey() {
    return `email-conversation-history-metadata-${this.workspaceId}-${this.conversationId}`;
  }

  private get historyBlocksStorageKey() {
    return `email-conversation-history-blocks-${this.workspaceId}-${this.conversationId}`;
  }

  private get historyExpandedInitialValue() {
    return storage.get(this.historyExpandedStorageKey) || false;
  }

  private get historyMetadataInitialValue() {
    return storage.get(this.historyMetadataStorageKey);
  }

  private get historyBlocksInitialValue() {
    let blocks = storage.get(this.historyBlocksStorageKey);
    if (blocks) {
      return new BlocksDocument(blocks).blocks;
    }
    return undefined;
  }

  removeHistoryBlock(blocks: BlockList) {
    return blocks.filter((block) => block.type !== 'html');
  }

  @action expandEmailHistory() {
    this.api?.composer.commands.setSelectionToEnd();
    this.insertBlocks(
      [{ type: 'paragraph', text: '' }, ...this.conversationHistoryBlocks] as BlockList,
      this.emailHistoryConversationId,
    );
    this.api?.composer.commands.collapseSelection();
    this.api?.composer.commands.focus();

    this.historyExpanded = true;
    this.updateHistoryStorageParams();
    this.intercomEventService.trackAnalyticsEvent({
      action: 'expanded',
      object: 'email_history',
      section: 'composer',
      place: 'inbox',
      conversation_id: this.conversationId,
    });
  }

  updateHistoryStorageParams() {
    storage.set(this.historyExpandedStorageKey, true);
    storage.set(this.historyBlocksStorageKey, this.conversationHistoryBlocks);
    storage.set(this.historyMetadataStorageKey, this.emailHistoryMetadataId);
  }

  clearEmailHistoryParams() {
    this.repliedToPart = undefined;
    this.historyExpanded = false;
    this.emailHistoryApi.resetParams();
    storage.remove(this.historyExpandedStorageKey);
    storage.remove(this.historyBlocksStorageKey);
    storage.remove(this.historyMetadataStorageKey);
  }

  emailHistoryRemoved(composerBlocks: BlockList) {
    return this.historyExpanded && !composerBlocks.any((block: Block) => block.type === 'html');
  }

  get showExpandEmailHistoryButton() {
    return (
      this.emailConversationReply &&
      !this.isMergedConversation &&
      this.emailHistorySettingEnabled &&
      !this.historyExpanded
    );
  }

  get emailHistorySettingEnabled() {
    return !this.session.workspace.disabledEmailConversationHistory;
  }

  get inlineAttachmentSettingEnabled(): boolean {
    return this.session.workspace.attachUploadsInline;
  }

  get emailHistoryIdToSend() {
    if (
      !this.emailHistorySettingEnabled ||
      !this.emailConversationReply ||
      this.emailHistoryRemoved(this.blocksDoc.blocks)
    ) {
      return;
    }

    return this.emailHistoryMetadataId || this.repliedToPartMetadataId;
  }

  get repliedToPartMetadataId() {
    let part = this.repliedToPart || this.latestCommentPart;
    return part?.renderableData?.emailMetadata?.id;
  }

  get latestCommentPart() {
    if (!(this.args.conversation instanceof Conversation)) {
      return undefined;
    }
    return this.args.conversation.lastHumanCommentPart;
  }

  @action async loadAndExpandEmailHistory(repliedToPartId?: number) {
    try {
      if (!this.conversationId || !this.emailConversationReply) {
        return;
      }

      let partId =
        repliedToPartId || this.repliedToPart?.entityId || this.latestCommentPart?.entityId;

      this.clearEmailHistoryParams();
      await this.emailHistoryApi.loadHistory(this.conversationId, partId);
      this.expandEmailHistory();
    } catch (error) {
      captureException(error);
      this.clearEmailHistoryParams();
    }
  }

  @task({ restartable: true })
  *loadEmailPartParticipants(
    conversationId: number | undefined,
    conversationPartId: number,
  ): TaskGenerator<void> {
    try {
      if (!conversationId) {
        return;
      }
      let response = yield this.inboxApi.fetchEmailPartParticipants(
        conversationId,
        conversationPartId,
      );

      if (response.participants.length > 0) {
        let recipients = response.participants.map((participant: any) =>
          UserSummary.deserialize(participant),
        );
        this.ccEnabled
          ? this.handleLoadedEmailRecipients(recipients, conversationPartId)
          : this.onRecipientChange(recipients);
      }
    } catch (error) {
      captureException(error);
    }
  }

  get conversationHistoryBlocks() {
    return this.emailHistoryApi.historyBlocks || this.historyBlocksInitialValue || [];
  }

  // This getter mimics the logic of conversationHistoryBlocks to return the conversationId those blocks belong to
  get emailHistoryConversationId() {
    if (this.emailHistoryApi.historyBlocks) {
      // When we fetched the history via async request we should use the conversationId from the response
      return this.emailHistoryApi.historyConversationId;
    } else if (this.historyBlocksInitialValue) {
      // When we fetched the history from the local store we can use current conversationId as it was part of the store key
      return this.conversationId;
    } else {
      // Getting here means that there are no history and we will insert an empty paragraph into composer.
      // In this case it is OK to return current conversation id.
      return this.conversationId;
    }
  }

  get emailHistoryMetadataId() {
    return this.emailHistoryApi.historyMetadataId || this.historyMetadataInitialValue || undefined;
  }

  handleLoadedEmailRecipients(emailRecipients: UserSummary[], conversationPartId: number) {
    let part = this.conversationInstance?.renderableParts.find(
      (part) => part.entityId === conversationPartId,
    );
    if (!part) {
      this.onRecipientChange(emailRecipients);
      return;
    }

    //TODO This can probably all go in the reply recipient factory
    let recipients = part.renderableData?.emailMetadata
      ? getRecipientsListFromMetadata(part, emailRecipients)
      : getRecipientsForPart(this.conversationInstance as Conversation, part); //TODO Tidy this up

    this.onRecipientManagerChange(recipients);
  }

  trackEmailHistoryRemoval() {
    if (!this.emailConversationReply || this.conversationHistoryBlocks.length === 0) {
      return;
    }

    if (this.emailHistoryRemoved(this.blocksDoc.blocks)) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'removed',
        object: 'email_history',
        section: 'composer',
        place: 'inbox',
        conversation_id: this.conversationId,
      });
    }
  }

  trackEmailRecipientUsage() {
    if (replyChannelIsEmail(this.panes.replyPane.channel) && this.ccEnabled) {
      let numOfCcRecipients = this.recipientsList.cc.length;
      let numOfToRecipients = this.recipientsList.to.length;

      this.intercomEventService.trackAnalyticsEvent({
        action: 'sent',
        object: 'email_recipients',
        section: 'composer',
        place: 'inbox',
        conversation_id: this.conversationId ?? null,
        to_recipients_count: numOfToRecipients,
        cc_recipients_count: numOfCcRecipients,
      });
    }
  }

  // Once the teammate starts adding content to the composer, we want to remember the part they are replying to and so the history.
  // if a new message comes in while they are still writing the reply, the replied to part and the history won't change
  updateRepliedToPartOnBlocksChange(blocksDoc: BlocksDocument) {
    // if the history has been expanded, we don't want to update the repliedToPart
    if (!this.showExpandEmailHistoryButton) {
      return;
    }

    // if the composer is empty, we want to clear the repliedToPart
    if (blocksDoc.blocks.length === 0) {
      this.repliedToPart = undefined;
    } else if (!this.repliedToPart) {
      this.repliedToPart = this.latestCommentPart;
      // load and expand the history by default if the part being replied to is from an external sender
      if (
        this.repliedToPart?.renderableType === RenderableType.UserEmailComment &&
        (this.repliedToPart.renderableData as UserEmailComment).fromExternalSender
      ) {
        this.loadAndExpandEmailHistory();
      }
    }
  }

  get isMergedConversation() {
    return this.args.conversation instanceof Conversation && this.args.conversation.isMerged;
  }

  get isSendChannelValid() {
    if (
      this.session.workspace.isFeatureEnabled('team-product-guidance-helpdesk-setup-wip') &&
      this.isNewConversation
    ) {
      if (this.isChatCurrentChannel) {
        return this.args.canSendMessenger;
      } else if (this.isEmailCurrentChannel) {
        return true;
      } else if (this.isSMSCurrentChannel) {
        return this.args.canSendSMS;
      } else if (this.isWhatsappCurrentChannel) {
        return this.args.canSendWhatsapp;
      }
      return false;
    }
    return true;
  }

  get isEmailSendChannelValid() {
    if (
      this.session.workspace.isFeatureEnabled('team-product-guidance-helpdesk-setup-wip') &&
      this.isEmailCurrentChannel &&
      this.isNewConversation
    ) {
      return this.args.canSendEmail;
    }
    return true;
  }

  get sendDisabledMessage() {
    if (this.isChatCurrentChannel) {
      return this.intl.t('inbox.new-conversation.install-chat.disabled');
    } else if (this.isSMSCurrentChannel) {
      return this.intl.t('inbox.new-conversation.install-sms.disabled');
    } else if (this.isWhatsappCurrentChannel) {
      return this.intl.t('inbox.new-conversation.install-whatsapp.disabled');
    }
    return;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationReplyComposer': typeof ReplyComposer;
    'inbox2/conversation-reply-composer': typeof ReplyComposer;
  }
}
