/* import __COLOCATED_TEMPLATE__ from './attribute-list-item.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import moment from 'moment-timezone';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { type UserAttributeWithIconLinkParams } from './user-details';
import { type InterfaceIconSet } from '@intercom/pulse/lib/interface-icons';
import type Inbox from 'embercom/objects/inbox/inboxes/inbox';
import type User from 'embercom/objects/inbox/user';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';
import type PALETTE from '@intercom/pulse/lib/palette';

interface Signature {
  Args: {
    isLoading: boolean;
    user?: User;
    attribute?: UserAttributeWithIconLinkParams;
    onUserAttributeUpdated?: (attribute: UserAttributeWithIconLinkParams, value?: any) => unknown;
    key?: string;
    hideLabel?: boolean;
    name?: string;
    type?: string;
    value?: string;
    emailIdentifiesUser?: boolean;
    linkParams?: UserAttributeWithIconLinkParams['linkParams'];
    icon?: InterfaceIconName;
    iconColor?: keyof typeof PALETTE;
    iconTooltipText?: string;
    iconSet?: InterfaceIconSet;
    description?: string;
    shouldShowOpenInNewTab?: boolean;
    userId?: string;
    analyticsSection?: string;
    fallbackText?: string;
    activeInbox?: Inbox;
    conversationId?: number;
    showPinIcon: boolean;
    isPinned?: boolean;
    isDuplicateAttribute?: boolean;
    togglePin?: (key: string) => unknown;
    hideRowIcon?: boolean;
  };
  Blocks: {
    icon: [];
  };
}

export default class AttributeListItem extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare intercomEventService: any;

  @tracked isHovered = false;

  get isUrl(): boolean {
    return typeof this.text === 'string' && !!this.text?.match(/^http(?:s?):\/\//);
  }

  get emailParts() {
    return this.args.value?.split('@');
  }

  get linkEnabled() {
    return !!this.args.linkParams;
  }

  get text() {
    let { type, value } = this.args;
    if (type === 'role') {
      if (value === 'user_role') {
        return this.intl.t('inbox.conversation-details-sidebar.role.user');
      } else if (value === 'contact_role') {
        return this.intl.t('inbox.conversation-details-sidebar.role.contact');
      } else {
        return;
      }
    } else if (type === 'timestamp' || type === 'date') {
      return value ? moment(value).fromNow() : value;
    } else if (value?.toString() === 'false' || value?.toString() === 'true') {
      return this.intl.t(`inbox.conversation-details-sidebar.boolean.${value}`);
    } else {
      return value;
    }
  }

  get showOpenInNewTab(): boolean {
    return this.args.attribute !== undefined && (this.args.shouldShowOpenInNewTab ?? false);
  }

  get showCompaniesDisplay() {
    return (
      this.args.attribute?.key === 'companyName' &&
      this.args.user?.companies &&
      this.args.user?.companies.length > 0
    );
  }

  @action togglePin() {
    if (!this.args.showPinIcon) {
      return;
    }

    this.args.togglePin?.(this.args.key!);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::SidebarSections::AttributeListItem': typeof AttributeListItem;
    'inbox2/conversation-details-sidebar/sidebar-sections/attribute-list-item': typeof AttributeListItem;
  }
}
