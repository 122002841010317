/* import __COLOCATED_TEMPLATE__ from './merged-conversation.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type RenderablePart from 'embercom/objects/inbox/renderable-part';
import type PartGroup from 'embercom/objects/inbox/conversation-stream/part-group';
import MergedPrimaryConversation from 'embercom/objects/inbox/renderable/merged-primary-conversation';
import type MergedSecondaryConversation from 'embercom/objects/inbox/renderable/merged-secondary-conversation';
import { action } from '@ember/object';
import type InboxSidebarService from 'embercom/services/inbox-sidebar-service';
import type IntlService from 'embercom/services/intl';

interface MergeConversationEventPart extends RenderablePart {
  renderableData: MergedPrimaryConversation | MergedSecondaryConversation;
}

export interface MergeConversationEventGroup extends PartGroup {
  parts: Array<MergeConversationEventPart>;
}

interface Args {
  partGroup: MergeConversationEventGroup;
}

export default class MergedConversationSection extends Component<Args> {
  @service declare inboxSidebarService: InboxSidebarService;
  @service declare intercomEventService: any;
  @service declare intl: IntlService;

  get renderableData() {
    return this.args.partGroup.parts[0].renderableData;
  }

  get adminSummary() {
    return this.renderableData.adminSummary;
  }

  get mergedConversationId() {
    if (this.renderableData instanceof MergedPrimaryConversation) {
      return this.renderableData.mergedConversationId;
    } else {
      return this.renderableData.mergeIntoConversationId;
    }
  }

  get conversationTypeText() {
    if (this.renderableData instanceof MergedPrimaryConversation) {
      return this.intl.t('inbox.merge-into.merge.from-conversation', {
        name: this.adminSummary.name,
      });
    } else {
      return this.intl.t('inbox.merge-into.merge.into-conversation', {
        name: this.adminSummary.name,
      });
    }
  }

  get conversationSubject() {
    if (this.renderableData instanceof MergedPrimaryConversation) {
      return this.renderableData.mergedConversationSubject;
    } else {
      return this.renderableData.mergeIntoConversationSubject;
    }
  }

  @action openMergedConversation() {
    if (this.mergedConversationId) {
      this.inboxSidebarService.previewConversationById(this.mergedConversationId);

      this.intercomEventService.trackAnalyticsEvent({
        action: 'viewed',
        object: 'merged_conversation',
        section: 'respond',
        place: 'conversation_part',
      });
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::PartGroups::MergedConversation': typeof MergedConversationSection;
    'inbox2/conversation-stream/part-groups/merged-conversation': typeof MergedConversationSection;
  }
}
