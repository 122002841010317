/* import __COLOCATED_TEMPLATE__ from './attributes.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import type Conversation from 'embercom/objects/inbox/conversation';
import type Inbox from 'embercom/objects/inbox/inboxes/inbox';
import { type CompanyAttributes } from 'embercom/objects/inbox/company';
import type ExternalPlan from 'embercom/objects/inbox/external-plan';
import { type UserAttributeWithIconLinkParams } from '../user-details';

interface Args {
  isLoading: boolean;
  attributes: UserAttributeWithIconLinkParams[];
  plans: ExternalPlan[];
  company: CompanyAttributes;
  conversation: Conversation;
  activeInbox: Inbox;
  showPinIcon: boolean;
  hideRowIcon?: boolean;
  togglePin: () => unknown;
  hideLabel?: boolean;
}

interface Signature {
  Args: Args;
}

export default class Inbox2CompanyDetailsAttributes extends Component<Signature> {
  get planName() {
    let plan = this.args.plans.find((plan) => plan.id === this.args.company.plan_id?.toString());
    if (!plan) {
      return '';
    }

    return plan.name;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::SidebarSections::CompanyDetails::Attributes': typeof Inbox2CompanyDetailsAttributes;
    'inbox2/conversation-details-sidebar/sidebar-sections/company-details/attributes': typeof Inbox2CompanyDetailsAttributes;
  }
}
