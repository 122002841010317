/* RESPONSIBLE TEAM: team-help-desk-experience */
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import { type Block } from '@intercom/interblocks.ts';
import { translationExists } from 'embercom/helpers/render-blocks';
import { type Translations } from 'embercom/components/inbox2/conversation-stream/interblocks-component';
import type PartGroup from 'embercom/objects/inbox/conversation-stream/part-group';

const TRANSLATABLE_TYPES = [
  RenderableType.AdminComment,
  RenderableType.UserComment,
  RenderableType.UserEmailComment,
];

interface RenderablePartWithBlocks {
  renderableData: { blocks?: BlockWithTranslations[] };
}

type BlockWithTranslations = Block & {
  translations?: Translations;
  original?: string;
};

export function translatedPartExists(partGroup: PartGroup): boolean {
  return !!partGroup.parts.any((part) => {
    if (!TRANSLATABLE_TYPES.includes(part.renderableType)) {
      return false;
    }
    let renderablePartWithBlocks = part as unknown as RenderablePartWithBlocks;
    return translationExists(renderablePartWithBlocks.renderableData?.blocks || []);
  });
}

export function getLanguageCodeFromPartGroup(
  partGroup: PartGroup,
  original = false,
): string | undefined {
  if (!translatedPartExists(partGroup)) {
    return undefined;
  }
  for (let part of partGroup.parts) {
    let renderablePartWithBlocks = part as unknown as RenderablePartWithBlocks;
    let blocks = renderablePartWithBlocks.renderableData?.blocks || [];
    if (translationExists(blocks)) {
      for (let block of blocks) {
        if ('translations' in block && 'original' in block) {
          if (original) {
            return block.original as string;
          } else {
            let languages = Object.keys(block.translations as Translations);
            let translated_languages = languages.filter((lang) => lang !== block.original);
            if (translated_languages.length > 0) {
              return translated_languages[0];
            }
          }
        }
      }
    }
  }

  return undefined;
}

export function getLanguageFromPartGroup(partGroup: PartGroup, locale: string, original = false) {
  let languageCode = getLanguageCodeFromPartGroup(partGroup, original);
  if (languageCode) {
    return getLanguageFromCode(languageCode, locale);
  }
  return languageCode;
}

export function getLanguageFromCode(languageCode: string, locale: string): string {
  try {
    let languageNames = new Intl.DisplayNames([locale], { type: 'language' });
    return languageNames.of(languageCode) || '';
  } catch (e) {
    // Fallback in case the language code is invalid or not supported
    return languageCode;
  }
}
