/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable @intercom/intercom/no-component-inheritance */

import { Resource } from 'ember-resources/core';
import { type Named } from 'ember-resources/core/types';

import {
  InboxMentionsStatus,
  isConversationStateEqual,
  isConversationInInbox,
  type InboxStateOption,
} from 'embercom/models/data/inbox/inbox-filters';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { type SortParams } from 'embercom/services/inbox-api';
import type InboxApi from 'embercom/services/inbox-api';
import type ConversationSummary from 'embercom/objects/inbox/conversation-summary';

import { taskFor } from 'ember-concurrency-ts';
import { registerDestructor } from '@ember/destroyable';
import { task } from 'ember-concurrency-decorators';
import type Inbox from 'embercom/objects/inbox/inboxes/inbox';
import { isSameInbox } from 'embercom/objects/inbox/inboxes/inbox';
import type ConversationListResource from 'embercom/objects/inbox/conversation-list-resource';
import { action } from '@ember/object';
import type InboxState from 'embercom/services/inbox-state';
import { InboxCategory } from 'embercom/models/data/inbox/inbox-categories';
import { InboxType } from 'embercom/models/data/inbox/inbox-types';
import type Conversation from 'embercom/objects/inbox/conversation';
import { cancel, later, schedule } from '@ember/runloop';
import { type EmberRunTimer } from 'ember-lifeline/types';
import { random } from 'underscore';
import { type InboxContentsChangedEvent } from 'embercom/services/nexus';
import type Nexus from 'embercom/services/nexus';
import { NexusEventName, NexusFallbackPoller } from 'embercom/services/nexus';
import type TracingService from 'embercom/services/tracing';
import { type UpdateMessage } from 'embercom/services/conversation-updates';
import type ConversationUpdates from 'embercom/services/conversation-updates';
// @ts-ignore
import { cached } from 'tracked-toolbox';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import type Inbox2Counters from 'embercom/services/inbox2-counters';
import type ApplicationInstance from '@ember/application/instance';
import type Session from 'embercom/services/session';
import type Transition from '@ember/routing/transition';

import { createMachine, interpret, assign } from 'xstate';
import type { InterpreterFrom } from 'xstate';
import type LogService from 'embercom/services/log-service';
import Admin from 'embercom/objects/inbox/inboxes/admin';
import type LbaMetricsService from 'embercom/services/lba-metrics-service';
import { ConversationState } from 'embercom/objects/inbox/conversation';

const BACKGROUND_RELOAD_THROTTLE = 1000;
const BACKGROUND_RELOAD_BACKOFF_INCREMENT = 1000;
const BACKGROUND_RELOAD_MAX_BACKOFF = 5000;
const CONVERSATIONS_PER_PAGE = 20;
const SCHEDULE_SYNC_JITTER = 200;

export type ConversationIdsWithContext = Record<
  string,
  {
    stateMatches: boolean;
    inboxMatches: boolean;
    transitioned: boolean;
  }
>;

interface Args {
  inbox?: Inbox;
  selectedStateOption: InboxStateOption;
  selectedSortOption: SortParams;
  selectedMentionsStatus?: InboxMentionsStatus;
  selectedConversations?: number;
  onResetList: () => unknown;
  onConversationStateChanged: (
    conversation: ConversationSummary,
    nextNavigableConversation?: ConversationSummary,
  ) => Promise<Transition | null>;
}

export default class ConversationListInboxResource
  extends Resource<Named<Args>>
  implements ConversationListResource
{
  @service declare session: Session;
  @service declare inboxApi: InboxApi;
  @service declare inboxState: InboxState;
  @service declare nexus: Nexus;
  @service declare tracing: TracingService;
  @service declare conversationUpdates: ConversationUpdates;
  @service declare inbox2Counters: Inbox2Counters;
  @service declare logService: LogService;
  @service declare lbaMetricsService: LbaMetricsService;

  @tracked countTotalConversations = 0;
  @tracked private localConversations: ConversationSummary[] = [];
  @tracked private transitionedLocalConversationIds = new Set<number>();
  @tracked machineState = 'Pending';

  private scheduledSync?: EmberRunTimer;
  private poller = new NexusFallbackPoller(this);
  private stateMachine: InterpreterFrom<typeof this.setupStateMachine>;

  private args!: Args;
  private pageSize = CONVERSATIONS_PER_PAGE;

  constructor(owner: ApplicationInstance) {
    super(owner);

    let visibilityListener = this.onVisibilityChange.bind(this);
    addEventListener('visibilitychange', visibilityListener);

    this.nexus.addListener(
      NexusEventName.InboxContentsChanged,
      this.handleInboxContentsChangedEvent,
    );

    this.stateMachine = interpret(this.setupStateMachine());
    this.stateMachine.onTransition((state, _event) => {
      if (state.changed) {
        this.machineState = state.toStrings().pop() as string;
      }
      // uncomment for verbose transition logging
      // console.log('[STATE MACHINE]', state.toStrings(), state.value, state.changed, event, state);
    });
    this.stateMachine.start();

    registerDestructor(this, () => {
      taskFor(this.fetchConversations).cancelAll();
      this.teardownSubscriptions();
      this.stateMachine.stop();
      removeEventListener('visibilitychange', visibilityListener);
      this.nexus.removeListener(
        NexusEventName.InboxContentsChanged,
        this.handleInboxContentsChangedEvent,
      );
    });
  }

  private onVisibilityChange() {
    if (document.visibilityState === 'visible') {
      this.stateMachine.send({ type: 'VISIBILITY_VISIBLE' });
    } else {
      this.stateMachine.send({ type: 'VISIBILITY_HIDDEN' });
    }
  }

  setupStateMachine() {
    let machine = createMachine(
      {
        predictableActionArguments: true,
        tsTypes: {} as import('./conversation-list-inbox-resource.typegen').Typegen0,
        id: 'ConversationList',
        schema: {
          context: {} as {
            pendingReload: boolean;
            reloadAttempts: number;
          },
          events: {} as
            | { type: 'LOAD_INBOX'; shouldCallResetHandler: boolean; reason: string }
            | { type: 'LOAD_MORE' }
            | { type: 'BACKGROUND_RELOAD' }
            | { type: 'SUCCESS' }
            | { type: 'ERROR' }
            | { type: 'VISIBILITY_VISIBLE' }
            | { type: 'VISIBILITY_HIDDEN' }
            | { type: 'RETRY'; shouldCallResetHandler: boolean; reason: string }
            | { type: 'PAUSE_UPDATES' }
            | { type: 'RESUME_UPDATES' },
        },
        initial: 'Pending',
        context: {
          pendingReload: false,
          reloadAttempts: 0,
        },
        on: {
          LOAD_INBOX: {
            target: 'LoadingInbox',
            internal: false,
            actions: 'clearPendingReload',
          },
        },
        states: {
          Pending: {},
          LoadingInbox: {
            initial: 'Loading',
            states: {
              Loading: {
                entry: 'fetchConversationsForInbox',
                exit: 'cleanupFetchConversations',
                on: {
                  SUCCESS: { target: '#ConversationList.LoadedInbox' },
                  ERROR: { target: 'Error' },
                  BACKGROUND_RELOAD: { actions: 'markPendingReload' },
                },
              },
              Error: {
                on: {
                  RETRY: { target: 'Loading' },
                },
              },
            },
          },
          LoadingMore: {
            initial: 'Loading',
            states: {
              Loading: {
                entry: 'fetchMoreConversations',
                exit: 'cleanupFetchConversations',
                on: {
                  SUCCESS: { target: '#ConversationList.LoadedInbox' },
                  ERROR: { target: 'Error' },
                  BACKGROUND_RELOAD: { actions: 'markPendingReload' },
                  PAUSE_UPDATES: { target: '#ConversationList.Paused' },
                },
              },
              Error: {
                on: {
                  RETRY: { target: 'Loading' },
                },
              },
            },
          },
          LoadingInBackground: {
            initial: 'CheckBrowserVisible',
            exit: 'clearReloadAttempts',
            on: {
              LOAD_MORE: { target: '#ConversationList.LoadingMore' },
              PAUSE_UPDATES: { target: '#ConversationList.Paused' },
            },
            states: {
              CheckBrowserVisible: {
                on: {
                  VISIBILITY_VISIBLE: { target: 'Loading' },
                },
                always: [
                  {
                    target: 'Loading',
                    cond: 'browserIsFocused',
                  },
                ],
              },
              Loading: {
                entry: ['clearPendingReload', 'fetchConversationsInBackground'],
                exit: 'cleanupFetchConversations',
                on: {
                  SUCCESS: { target: 'Waiting' },
                  ERROR: { target: 'Error' },
                  BACKGROUND_RELOAD: { actions: 'markPendingReload' },
                },
              },
              Waiting: {
                on: {
                  BACKGROUND_RELOAD: { actions: 'markPendingReload' },
                },
                after: {
                  reloadThrottle: {
                    target: '#ConversationList.LoadedInbox',
                  },
                },
              },
              Error: {
                entry: 'incrementReloadAttempts',
                after: {
                  withBackoff: {
                    target: '#ConversationList.LoadingInBackground',
                  },
                },
              },
            },
          },
          Paused: {
            on: {
              BACKGROUND_RELOAD: { actions: 'markPendingReload' },
              RESUME_UPDATES: { target: 'LoadedInbox' },
            },
          },
          LoadedInbox: {
            on: {
              BACKGROUND_RELOAD: 'LoadingInBackground',
              LOAD_MORE: { target: 'LoadingMore' },
              PAUSE_UPDATES: { target: 'Paused' },
            },
            always: [{ target: 'LoadingInBackground', cond: 'pendingReload' }],
          },
        },
      },
      {
        actions: {
          cleanupFetchConversations: () => {
            taskFor(this.fetchConversations).cancelAll();
          },
          fetchConversationsInBackground: (_context, _event) => {
            taskFor(this.fetchConversations).perform({
              reason: 'inbox_contents_changed_event',
              activeConversationId: this.inboxState.activeConversationId,
              lowPriority: true,
            });
          },
          fetchMoreConversations: (_context, _event) => {
            taskFor(this.fetchConversations).perform({
              reason: 'load_more',
            });
          },
          fetchConversationsForInbox: (_context, event) => {
            taskFor(this.fetchConversations).perform({
              shouldCallResetHandler: event.shouldCallResetHandler,
              reason: event.reason,
            });
          },
          markPendingReload: assign({ pendingReload: () => true }),
          clearPendingReload: assign({ pendingReload: () => false }),
          incrementReloadAttempts: assign({
            reloadAttempts: (context) => context.reloadAttempts + 1,
          }),
          clearReloadAttempts: assign({ reloadAttempts: () => 0 }),
        },
        guards: {
          pendingReload: (context, _event) => {
            return context.pendingReload;
          },
          browserIsFocused: (_context, _event) => {
            return document.visibilityState === 'visible';
          },
        },
        delays: {
          withBackoff: (context, _event) => {
            return Math.min(
              context.reloadAttempts * BACKGROUND_RELOAD_BACKOFF_INCREMENT,
              BACKGROUND_RELOAD_MAX_BACKOFF,
            );
          },
          reloadThrottle: (_context, _event) => {
            return BACKGROUND_RELOAD_THROTTLE;
          },
        },
      },
    );

    return machine;
  }

  modify(_: unknown[], args: Args) {
    this.teardownSubscriptions();

    let isNewList = this.isNewList(args, this.args);
    let hasPreviousArgs = !!this.args;
    this.args = args;

    if (!this.args.inbox) {
      return;
    }

    if (isNewList) {
      this.localConversations = [];
      this.countTotalConversations = 0;
      this.transitionedLocalConversationIds = new Set();
      this.pageSize = CONVERSATIONS_PER_PAGE;

      // We should be able to have the state machine itself figure this out
      // but for now just pass it in
      let shouldCallResetHandler = !!hasPreviousArgs;
      let reason = hasPreviousArgs ? 'inbox_or_filters_changed' : 'first_load';

      this.stateMachine.send({ type: 'LOAD_INBOX', shouldCallResetHandler, reason });
    }

    this.poller.start(() => taskFor(this.reload).perform());
    this.conversationUpdates.subscribe(this.applyConversationUpdates);

    if (this.args.inbox?.id) {
      this.nexus.subscribeTopics([`inbox/${this.args.inbox.type}/${this.args.inbox.id}`]);
    }

    if (args.selectedConversations && args.selectedConversations > 0) {
      this.pauseUpdates();
    } else {
      this.resumeUpdates();
    }
  }

  private teardownSubscriptions() {
    this.cancelScheduledSync();
    this.poller.stop();
    this.conversationUpdates.unsubscribe(this.applyConversationUpdates);

    if (this.args?.inbox?.id) {
      this.nexus.unsubscribeTopics([`inbox/${this.args.inbox.type}/${this.args.inbox.id}`]);
    }
  }

  removeConversation(conversation: Conversation) {
    this.localConversations = this.localConversations.filter((c) => c.id !== conversation.id);
  }

  @cached
  get conversationIdsWithContext(): ConversationIdsWithContext {
    let idsWithContext: ConversationIdsWithContext = {};

    this.localConversations.forEach((conversation) => {
      idsWithContext[conversation.id] = {
        stateMatches: isConversationStateEqual(
          this.args.selectedStateOption,
          conversation.state,
          conversation.ticketState,
        ),
        inboxMatches: isConversationInInbox(this.args.inbox, conversation),
        transitioned: this.transitionedLocalConversationIds.has(conversation.id),
      };
    });

    return idsWithContext;
  }

  @cached
  get conversations() {
    // For mentions Inbox, we don't need to sort locally.
    if (this.isMentionsInbox) {
      return this.localConversations;
    }

    let conversations = this.localConversations.filter((conversation) => {
      let context = this.conversationIdsWithContext[conversation.id];

      // Redacted conversations will have an empty value for state, so we don't
      // have a way to tell if they are in context. We treat them as if they are.
      let isInContext =
        (conversation.redacted || context.stateMatches) &&
        context.inboxMatches &&
        !context.transitioned;

      return isInContext || conversation.id === this.inboxState.activeConversationId;
    });

    let sort = this.args.selectedSortOption;
    if (sort.sort_field === 'sorting_updated_at') {
      if (sort.sort_direction === 'asc') {
        conversations = conversations.sortBy('lastUpdated');
      } else if (sort.sort_direction === 'desc') {
        conversations = conversations.sortBy('lastUpdated').reverse();
      }
    }

    return conversations;
  }

  get nextWindowSize() {
    return Math.min(
      this.localConversations.length + CONVERSATIONS_PER_PAGE,
      this.countTotalConversations,
    );
  }

  get inboxConversationsCount() {
    if (this.args.inbox && this.shouldUpdateCounters()) {
      return this.inbox2Counters.countForInbox(this.args.inbox);
    } else {
      return this.countTotalConversations;
    }
  }

  // Returns the count of additional conversations that we may be fetching.
  // Useful to show placeholders, etc.
  get countAdditionalConversationsBeingFetched() {
    if (!this.isLoading) {
      return 0;
    }

    return this.pageSize - this.localConversations.length;
  }

  get isLoadingInForeground() {
    return ['LoadingInbox.Loading', 'LoadingMore.Loading'].includes(this.machineState);
  }

  get isLoading() {
    return ['LoadingInbox.Loading', 'LoadingInBackground.Loading', 'LoadingMore.Loading'].includes(
      this.machineState,
    );
  }

  get hasFetchingError() {
    return this.machineState === 'LoadingInbox.Error';
  }

  get hasLoadMoreError() {
    return this.machineState === 'LoadingMore.Error';
  }

  @action handleInboxContentsChangedEvent(e: InboxContentsChangedEvent) {
    schedule('actions', () => {
      let data = e.eventData;
      let currentInboxChanged = data.inboxes.any((inbox) => isSameInbox(inbox, this.args.inbox));
      if (!currentInboxChanged) {
        return;
      }

      taskFor(this.reload).perform({ reason: 'inbox_contents_changed_event' });
    });
  }

  @action loadMore() {
    if (this.nextWindowSize > this.pageSize) {
      this.pageSize = this.nextWindowSize;
      this.stateMachine.send({ type: 'LOAD_MORE' });
    }
  }

  @action retry() {
    this.stateMachine.send({
      type: 'RETRY',
      shouldCallResetHandler: true,
      reason: 'retry_after_error',
    });
  }

  private pauseUpdates() {
    this.stateMachine.send({
      type: 'PAUSE_UPDATES',
    });
  }

  private resumeUpdates() {
    this.stateMachine.send({
      type: 'RESUME_UPDATES',
    });
  }

  // doesn't need to be a task but that's our interface for now
  @task *reload(_opts?: any) {
    yield this.stateMachine.send({ type: 'BACKGROUND_RELOAD' });
  }

  // TODO - this should be private, but is currently public in the interface
  @task *fetchConversations({
    shouldCallResetHandler,
    activeConversationId,
    reason,
    lowPriority = false,
  }: {
    shouldCallResetHandler?: boolean;
    activeConversationId?: number;
    reason?: string;
    lowPriority?: boolean;
  } = {}) {
    try {
      let { inbox } = this.args;

      let tracingAssignments = this.startTracingAssignments(inbox, reason);

      let { conversations, activeConversation, total, validFor, serverTime } =
        (yield this.listConversations(activeConversationId, reason, lowPriority)) as {
          conversations: ConversationSummary[];
          activeConversation?: ConversationSummary;
          total: number;
          validFor: number;
          serverTime?: Date;
          inbox_id: string;
          reason: string;
        };

      this.transitionedLocalConversationIds = new Set();

      // On reload, it's possible that the current conversation is not in the
      // list. In that case, we want to add it back provided it was previously
      // in the list.
      if (activeConversationId && !conversations.any((c) => c.id === activeConversationId)) {
        let idx = this.conversations.findIndex((c) => c.id === activeConversationId);
        if (activeConversation && idx !== -1) {
          conversations.insertAt(Math.min(idx, conversations.length), activeConversation);
          this.transitionedLocalConversationIds = new Set([activeConversationId]);
        }
      }

      conversations.map((conversation) => {
        this.conversationUpdates
          .updatesAfter(conversation.id, conversation.lastUpdated)
          .forEach((update) => update.apply(conversation));
      });

      if (tracingAssignments) {
        this.traceAssignments(conversations, serverTime);
      }

      this.localConversations = conversations;
      this.countTotalConversations = total;
      if (this.args.inbox && this.shouldUpdateCounters()) {
        this.inbox2Counters.updateCount(this.args.inbox, total);
      }

      if (validFor) {
        this.scheduleNextSync(validFor);
      }

      if (shouldCallResetHandler) {
        this.args.onResetList?.();
      }

      this.stateMachine.send({ type: 'SUCCESS' });
    } catch (e) {
      this.stateMachine.send({ type: 'ERROR' });
      this.tracing.onError(e, 'Inbox');
      throw e;
    }
  }

  private shouldTraceAssignments(inbox?: Inbox, reason?: string) {
    return (
      inbox instanceof Admin &&
      inbox.id === this.session.teammate.id.toString() &&
      reason === 'inbox_contents_changed_event'
    );
  }

  private startTracingAssignments(inbox?: Inbox, reason?: string): boolean {
    if (!this.shouldTraceAssignments(inbox, reason)) {
      return false;
    }

    this.tracing.startRootSpan({
      name: 'customMeasurement',
      resource: 'inbox2:admin_inbox_contents_changed',
    });

    return true;
  }

  private traceAssignments(conversations: ConversationSummary[], serverTime?: Date) {
    let newConversations = conversations.reject((conversation) =>
      this.localConversations.any((c) => c.id === conversation.id),
    );

    let oldestAssignedConversation = newConversations.reduce(
      (oldest: ConversationSummary | null, current) => {
        let oldestLastAssignedAt = oldest?.assignmentTracingData?.lastAssignedAt;
        let currentLastAssignedAt = current?.assignmentTracingData?.lastAssignedAt;

        if (!oldest && currentLastAssignedAt) {
          return current;
        }

        if (
          currentLastAssignedAt &&
          oldestLastAssignedAt &&
          currentLastAssignedAt < oldestLastAssignedAt
        ) {
          return current;
        }

        return oldest;
      },
      null,
    );

    if (
      oldestAssignedConversation &&
      oldestAssignedConversation?.assignmentTracingData?.lastAssignedAt &&
      serverTime
    ) {
      let {
        lastAssignedAt,
        lastLbaInitialEnqueueAt,
        lastLbaCalculateEnqueuedAt,
        lastLbaAssignEnqueuedAt,
        lastLbaAssignedAt,
        lastLbaTeamAssignedAt,
      } = oldestAssignedConversation.assignmentTracingData;

      let diff = serverTime.getTime() - lastAssignedAt.getTime();

      let teammateWaitMetrics = this.lbaMetricsService.getTeammateWaitMetrics(
        lastLbaTeamAssignedAt?.getTime(),
        serverTime.getTime(),
      );

      this.tracing.tagActiveSpan({
        conversation_id: oldestAssignedConversation.id,
        assignment_latency_ms: diff,
        num_new_conversations: newConversations.length,
        assignment_part_type: oldestAssignedConversation.lastPartCreatedType,
        assignment_server_time_ms: serverTime.getTime(),
        last_lba_initial_enqueue_at: lastLbaInitialEnqueueAt?.valueOf(),
        last_lba_calculate_enqueued_at: lastLbaCalculateEnqueuedAt?.valueOf(),
        last_lba_assign_enqueued_at: lastLbaAssignEnqueuedAt?.valueOf(),
        last_lba_assigned_at: lastLbaAssignedAt?.valueOf(),
        last_lba_teammate_waiting_time: teammateWaitMetrics?.teammateWaitTime,
        last_lba_trigger_event: teammateWaitMetrics?.lbaTriggerEvent,
      });
    }

    this.lbaMetricsService.newAssignmentReset(this.localConversations, conversations);
  }

  private shouldUpdateCounters(): boolean {
    if (!this.args.inbox) {
      return false;
    }

    if (this.isMentionsInbox) {
      if (this.args.selectedMentionsStatus === InboxMentionsStatus.Unread) {
        return true;
      }
    } else if (this.args.selectedStateOption === ConversationState.Open) {
      return true;
    }

    return false;
  }

  private scheduleNextSync(validForInSeconds: number, jitter = SCHEDULE_SYNC_JITTER) {
    this.cancelScheduledSync();
    this.scheduledSync = later(
      this,
      () => taskFor(this.reload).perform(),
      validForInSeconds * 1000 + random(jitter),
    );
  }

  private cancelScheduledSync() {
    this.scheduledSync && cancel(this.scheduledSync);
  }

  private isNewList(current: Args, previous?: Args) {
    if (!previous) {
      return true;
    }

    return (
      !isSameInbox(current.inbox, previous.inbox) ||
      current.selectedStateOption !== previous.selectedStateOption ||
      current.selectedSortOption.sort_field !== previous.selectedSortOption.sort_field ||
      current.selectedSortOption.sort_direction !== previous.selectedSortOption.sort_direction ||
      current.selectedMentionsStatus !== previous.selectedMentionsStatus
    );
  }

  private get isMentionsInbox() {
    return this.args.inbox?.type === InboxType.Mentions;
  }

  private async listConversations(
    activeConversationId?: number,
    reason?: string,
    lowPriority = false,
  ) {
    if (this.args.inbox === undefined) {
      throw new Error('Cannot call listConversations without an inbox');
    }

    let { inbox } = this.args;

    return ConversationListInboxResource.performListRequest(
      this.inboxApi,
      inbox,
      this.args.selectedStateOption,
      this.args.selectedSortOption,
      this.pageSize,
      this.args.selectedMentionsStatus,
      activeConversationId,
      {
        reason,
        lowPriority,
      },
    );
  }

  static performListRequest(
    api: InboxApi,
    inbox: { id: string; category: InboxCategory },
    selectedStateOption: InboxStateOption | undefined,
    selectedSortOption: SortParams,
    count?: number,
    selectedMentionsStatus?: InboxMentionsStatus,
    activeConversationId?: number,
    opts?: Partial<{ reason: string; lowPriority: boolean }>,
  ) {
    let state: InboxStateOption | undefined = selectedStateOption;
    let mentionsStatus: InboxMentionsStatus | undefined = selectedMentionsStatus;
    let isMentionsInbox = false;

    if (inbox.category === InboxCategory.Shared && inbox.id === 'mentions') {
      // For the mentions inbox, we'll load all conversations: open, closed, snoozed.
      state = undefined;
      isMentionsInbox = true;
    } else {
      // For non-mentions inboxes, mentionsStatus is not a valid param.
      mentionsStatus = undefined;
    }

    if (!count) {
      count = CONVERSATIONS_PER_PAGE;
    }

    return api.listConversations({
      category: inbox.category,
      id: inbox.id,
      state,
      orderBy: selectedSortOption,
      count,
      includeLatestConversation: false,
      mentionsStatus,
      activeConversationId,
      metadata: opts,
      includeLastAdminMentionedPart: isMentionsInbox,
    });
  }

  @action
  private async applyConversationUpdates(updates: UpdateMessage[]) {
    let conversationsWithStateChanges: Set<[ConversationSummary, ConversationSummary | undefined]> =
      new Set();

    updates.forEach((update) => {
      let conversation = this.localConversations.find(
        (conversation) => conversation.id === update.conversationId,
      );

      // Find the index of the conversation in the list of visible conversations,
      // so that we can decide the previous / next conversation.
      let idx = this.conversations.findIndex(
        (conversation) => conversation.id === update.conversationId,
      );
      if (!conversation) {
        return;
      }

      let nextConversation = this.conversations[idx + 1];
      let previousConversation = this.conversations[idx - 1];

      if (update.type === 'added') {
        update.entries.forEach((entry) => {
          entry.apply(conversation!);

          if (entry.part.renderableType === RenderableType.StateChange) {
            conversationsWithStateChanges.add([
              conversation!,
              nextConversation ?? previousConversation,
            ]);
          }
        });
        this.maybeUpdateCounters(conversation);
      } else if (update.type === 'removed') {
        update.entries.forEach((entry) => entry.rollback(conversation!));
      }
    });

    for (let [conversation, nextNavigableConversation] of conversationsWithStateChanges.values()) {
      await this.args.onConversationStateChanged?.(conversation, nextNavigableConversation);
    }
  }

  private maybeUpdateCounters(conversation: ConversationSummary) {
    if (!this.args.inbox || this.isMentionsInbox) {
      return;
    }

    // if we've been assigned out of this inbox, decrement the counter
    let inboxMatches = isConversationInInbox(this.args.inbox, conversation);
    if (!inboxMatches) {
      this.inbox2Counters.decrementCount(this.args.inbox);
      return;
    }

    // if the conversation has been closed / snoozed, decrement the counter
    if (this.args.selectedStateOption === ConversationState.Open) {
      let conversationIsOpen = isConversationStateEqual(
        ConversationState.Open,
        conversation.state,
        conversation.ticketState,
      );

      if (!conversationIsOpen) {
        this.inbox2Counters.decrementCount(this.args.inbox);
      }
      return;
    }

    // if the conversation has been re-opened, increment the counter
    if (
      this.args.selectedStateOption === ConversationState.Closed ||
      this.args.selectedStateOption === ConversationState.Snoozed
    ) {
      let conversationIsOpen = isConversationStateEqual(
        ConversationState.Open,
        conversation.state,
        conversation.ticketState,
      );

      if (conversationIsOpen) {
        this.inbox2Counters.incrementCount(this.args.inbox);
      }
      return;
    }
  }
}
