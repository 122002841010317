/* import __COLOCATED_TEMPLATE__ from './inbox-conversations-table.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import { inject as service } from '@ember/service';
import { registerDestructor } from '@ember/destroyable';
import Component from '@glimmer/component';
import { type TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import type Inbox from 'embercom/objects/inbox/inboxes/inbox';
import { tracked } from 'tracked-built-ins';
import { task } from 'ember-concurrency-decorators';
import type ConversationAttributeDescriptor from 'embercom/objects/inbox/conversation-attribute-descriptor';
import { type TicketType } from 'embercom/objects/inbox/ticket';
import type Session from 'embercom/services/session';
import { type ColumnDefinition } from 'embercom/objects/inbox/conversations-table-column';
import type ConversationsTableColumn from 'embercom/objects/inbox/conversations-table-column';
import {
  ColumnDefinitionsBuilder,
  ConversationsTableColumns,
  columnId,
} from 'embercom/objects/inbox/conversations-table-column';
import type InboxApi from 'embercom/services/inbox-api';
import type ConversationsTableSettings from 'embercom/services/conversations-table-settings';
import type ConversationTableEntry from 'embercom/objects/inbox/conversation-table-entry';
import { REQUIRED_CONVERSATIONS_TABLE_FIELDS } from 'embercom/objects/inbox/conversation-table-entry';
import { type SortDirection } from 'embercom/services/inbox-api';
import { action } from '@ember/object';
import {
  type InboxStateOption,
  isConversationStateEqual,
} from 'embercom/models/data/inbox/inbox-filters';
import type InboxState from 'embercom/services/inbox-state';
// @ts-ignore
import { cached, trackedReset } from 'tracked-toolbox';
import { getOwner } from '@ember/application';
import { InboxType } from 'embercom/models/data/inbox/inbox-types';
import Mentions from 'embercom/objects/inbox/inboxes/mentions';
import type RouterService from '@ember/routing/router-service';
import type FinQuestionAnswers from 'embercom/services/fin-question-answers';

interface Args {
  inbox: Inbox;
}

interface Signature {
  Element: HTMLElement;
  Args: Args;
  Blocks: {
    default: [];
  };
}

export default class InboxConversationsTable extends Component<Signature> {
  @service declare session: Session;
  @service declare inboxApi: InboxApi;
  @service declare conversationsTableSettings: ConversationsTableSettings;
  @service declare inboxState: InboxState;
  @service declare router: RouterService;
  @service declare intercomEventService: any;
  @service declare finQuestionAnswers: FinQuestionAnswers;

  @trackedReset('settings') selectedStateOption = this.settings.selectedStateOption;
  @trackedReset('settings') selectedMentionsStatus = this.settings.selectedMentionsStatus;
  @trackedReset('settings') selectedSortOption = this.settings.selectedSortOption;

  @tracked descriptors: ConversationAttributeDescriptor[] = [];
  @tracked ticketTypes: TicketType[] = [];
  @tracked columnDefinitions?: ColumnDefinition[];

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    taskFor(this.loadDynamicColumns).perform();

    registerDestructor(this, () => {
      taskFor(this.loadDynamicColumns).cancelAll();
    });
  }

  get isMentionsInbox() {
    return this.inboxState.activeInbox instanceof Mentions;
  }

  @cached
  get settings() {
    return this.conversationsTableSettings.getInboxSettings(this.args.inbox);
  }

  @cached
  get tableColumns() {
    if (!taskFor(this.loadDynamicColumns).lastComplete) {
      return undefined;
    }
    return new ConversationsTableColumns(
      getOwner(this),
      this.settings.selectedColumns,
      this.columnDefinitions!,
      this.ticketTypes,
    );
  }

  @task({ drop: true }) *loadDynamicColumns(): TaskGenerator<void> {
    let [descriptors, ticketTypes] = yield Promise.all([
      this.session.workspace.fetchConversationAttributeDescriptors(),
      this.inboxApi.listTicketTypes(),
    ]);

    this.descriptors = descriptors;
    this.ticketTypes = ticketTypes;

    this.columnDefinitions = new ColumnDefinitionsBuilder(
      getOwner(this),
      this.ticketTypes,
      this.descriptors,
      'inbox',
    ).columnDefinitions;
  }

  @action onPreviewConversation(conversation: ConversationTableEntry) {
    this.router.transitionTo('inbox.workspace.inbox.inbox.conversation.conversation', conversation);

    this.intercomEventService.trackAnalyticsEvent({
      action: 'opened',
      object: 'conversation',
      section: 'conversation_table',
      conversation_id: conversation.id,
    });
  }

  @action onClosePreview() {
    this.router.transitionTo('inbox.workspace.inbox.inbox.index', {
      queryParams: { skipRedirection: true },
    });
  }

  @action changeColumns(columns: ConversationsTableColumn[]) {
    this.tableColumns!.selectedColumns = columns.map((col) => ({
      id: columnId(col),
      width: col.width,
    }));
    this.saveSettings();
  }

  @action onSortColumn(column: ConversationsTableColumn, direction: SortDirection) {
    if (!column.sortField) {
      return;
    }

    this.inboxState.selectedConversations.clear();
    this.selectedSortOption = { sortField: column.sortField, direction };
    this.saveSettings();
  }

  @action changeStateOption(value: InboxStateOption) {
    this.inboxState.selectedConversations.clear();
    this.inboxState.clearActiveConversation();
    this.selectedStateOption = value;

    this.saveSettings();
  }

  saveSettings() {
    let commonArgs = {
      selectedColumns: this.tableColumns!.selectedColumns,
      selectedSortOption: this.selectedSortOption,
      selectedStateOption: this.selectedStateOption,
      selectedMentionsStatus: this.selectedMentionsStatus,
    };

    this.conversationsTableSettings.saveInboxSettings(this.args.inbox, { ...commonArgs });
  }

  get fields() {
    if (!this.tableColumns) {
      return [];
    }

    return [
      ...REQUIRED_CONVERSATIONS_TABLE_FIELDS,
      ...(this.args.inbox?.type === InboxType.Mentions ? ['last_admin_mentioned_part'] : []),
      ...this.tableColumns.columns.flatMap((column) => column.fields),
    ].uniq();
  }

  get hiddenColumns() {
    return this.tableColumns?.hiddenColumns ?? [];
  }

  get columns() {
    return this.tableColumns?.columns ?? [];
  }

  @action async onConversationStateChanged(
    conversation: ConversationTableEntry,
    nextNavigableConversation: ConversationTableEntry,
  ) {
    if (conversation.id !== this.inboxState.activeConversationId) {
      return null;
    }

    if (
      !this.isMentionsInbox &&
      !isConversationStateEqual(
        this.selectedStateOption,
        conversation.state!,
        conversation.ticketState,
      )
    ) {
      if (nextNavigableConversation) {
        return await this.router.transitionTo(
          'inbox.workspace.inbox.inbox.conversation.conversation',
          nextNavigableConversation,
        );
      } else {
        return await this.router.transitionTo('inbox.workspace.inbox.inbox.index', {
          queryParams: { skipRedirection: true },
        });
      }
    }

    return null;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::InboxConversationsTable': typeof InboxConversationsTable;
  }
}
